/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/idcard/_components/${view}.vue`)
}

let routes = [
    {
        name: 'student-id-card',
        path: 'student-card',
        component: loadView('Student/index'),
        meta: {
            auth: true,
            permission: 'staff-create'
        }
    }, {
        name: 'staff-id-card',
        path: 'staff-card',
        component: loadView('Staff/index'), 
        meta: {
            auth: true,
            permission: 'staff-create'
        }
    }
];

export default routes; 