<template>
  <div>
    <div class="mb-5">
      <v-chip
        @click="attachPlaceholder(item)"
        :key="key"
        v-for="(item, key) in placeholders"
        class="mr-2"
        style="cursor: pointer"
        x-small
        label
        >{{ item.text }}</v-chip
      >
    </div>
    <v-textarea
      :rows="rows"
      style="font-weight: bold"
      spellcheck="false"
      :value="template_text"
      class="mb-0"
      :label="label"
      @keyup="typeContent"
      outlined
    ></v-textarea>
  </div>
</template>
<script>
export default {
  props: {
    defaultContent: {
      type: String,
    },
    rows: {
      type: Number,
      default: 6,
    },
    defaultPlaceholder: {
      default: null,
    },
    showAlert: {
      default: true,
    },
    label: {
      default: "Input your text",
    },
    value: { type: String },
  },
  data: () => ({
    placeholders: [],
    my_sms: null,
    template_text: "",
  }),
  computed: {},
  watch: {
    value: function (v) {
      this.template_text = v;
    },
  },
  mounted() {
    if (this.defaultContent) {
      this.template_text = this.defaultContent;
    }
    if (this.defaultPlaceholder !== null) {
      this.placeholders = this.defaultPlaceholder;
    }
  },
  methods: {
    typeContent(e) {
      this.template_text = e.target.value;
      this.$emit("input", this.template_text);
    },
    attachPlaceholder(item) {
      if (item && item.placeholder) {
        this.template_text = this.template_text.trim();

        this.template_text += " " + item.placeholder;
        this.template_text = this.template_text.trim();
        this.$emit("input", this.template_text);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploaded-file-preview {
  width: 100%;
  align-items: center;
  overflow: hidden;
  margin-bottom: 15px;
  .icon-holder {
    width: 20%;
    padding: 5px 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    float: left;
    flex: 1;
    background-color: #1976d2 !important;
    border: 1px solid #1976d2;

    text-align: center;
  }
  .file-info-holder {
    border: 1px solid #ccc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: none;
    padding: 5px 15px;
    color: #777;
    width: 80%;
    float: right;
    position: relative;
    p {
      margin-bottom: 0;
      line-height: 1;
    }
    i,
    button {
      position: absolute;
      right: 5px;
      font-size: 18px;
      color: #666;
      top: 5px;
      cursor: pointer;
    }
  }
}
</style>
