<template>
    <div>
        <apexchart v-if="chartData.length" type="line" :options="chartOptions" :series="chartData"/>
    </div>
</template>
<script>
    export default {
        data: () => ({
            chartData: [
                {
                    name: 'Present',
                    data: [31, 40, 28, 51, 42, 109, 100]
                }, {
                    name: 'Leave',
                    data: [11, 32, 45, 32, 34, 52, 41]
                }, {
                    name: 'Abscent',
                    data: [5, 2, 5, 2, 4, 2, 1]
                }, {
                    name: 'Late',
                    data: [1, 1, 3, 4, 2, 2, 3]
                }
            ],
            chartOptions: {
                title: {
                    text: 'Grade-Wise Attendance',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            }
        }),
        mounted() {
        }
    }
</script>