<template>
    <div v-if="Object.keys(student).length && showInfo" class="student-semi-profile-container">
        <div class="brief_info">
            <div class="std_img">
                <img :src="logo" alt="">
            </div>
            <div class="std_info">
                <div>
                    <h3 style="display: flex;align-items: center">{{student.name}}
                        <!--<span class="chip_info" style="margin-left: 30px;">Chip Info</span>-->
                        <!--<span class="chip_info" style="margin-left: 10px;">Ano Info</span>-->
                    </h3>
                    <p>{{student.address}}</p>
                </div>
                <div v-if="student.details" class="d-flex mt-3">
                    <div class="info_std_item">
                        <v-icon color="black">more</v-icon>
                        Admission {{student.details.admission_date}}
                    </div>

                    <div class="info_std_item">
                        <v-icon color="#03BEFF">home</v-icon>
                        {{student.details.grade}} / {{student.details.section}}
                    </div>

                    <div class="info_std_item">
                        <v-icon color="#F96BE6">fingerprint</v-icon>
                        {{student.details.enroll_code}}
                    </div>

                    <div class="info_std_item">
                        <v-icon color="#FFD202">military_tech</v-icon>
                        Roll {{student.details.roll}}
                    </div>
                </div>
            </div>
            <div class="std_action">
                <v-tooltip bottom>
                    {{student.id}}
                    <template v-slot:activator="{ on, attrs }">
                        <router-link   :to="'/student/'+student.id">
                            <v-icon v-on="on" v-bind="attrs" color="primary">account_circle</v-icon>
                        </router-link>
                    </template>
                    <span>Go to Profile</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a @click.prevent="showInfo=false" v-bind="attrs"
                           v-on="on" href="">
                            <v-icon color="error">cancel</v-icon>
                        </a>
                    </template>
                    <span>Hide User Info</span>
                </v-tooltip>

            </div>
        </div>
        <div class="exam_information">
            <label>Guardian </label>
            <div class="extra_info_info">
                <p>{{student.guardian.name}} ({{student.guardian.relation}}), &nbsp;
                    <v-icon style="margin-left: 10px;" size="16" color="primary">phone</v-icon>
                    {{student.guardian.mobile || 'N/A'}}
                </p>
            </div>
        </div>
        <div class="extra_actions">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">

                    <v-icon v-bind="attrs"
                            v-on="on">more_horiz
                    </v-icon>
                </template>
                <v-list>
                    <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>
<script>
    import cookie from "js-cookie";

    import {mapActions, mapState} from 'vuex';

    export default {
        props: {
            student: {
                required: true
            },
            displayInfo: {
                default: true
            }
        },
        data: () => ({
            items: [
                {title: 'Visit Profile'},
                {title: 'Close Info'},
            ],
            showInfo: false,
        }),
        computed: {

            logo() {
                return this.student.avatar || cookie.get("_cl") || "https://image.ibb.co/cVTEsG/eAcademy.png";
            },
        },
        watch: {},
        mounted() {
            this.showInfo = this.displayInfo;
        },
        methods: {}


    }
</script>
<style lang="scss">
    .student-semi-profile-container {
        width: 100%;
        padding: 7px 7px;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
        background: white;
        border-radius: 20px;
        min-height: 100px;
        position: relative;

        .exam_information {
            padding: 2px 0 0 20px;
            display: flex;
            margin-top: 5px;
            label {
                width: 80px;
                margin-top: 10px;
            }
            .extra_info_info {
                padding-top: 10px;
                width: 100%;
                min-height: 20px;
                border-top: 1px solid #eee;
            }
            p{
                margin-bottom: 0;
            }
        }
        .brief_info {
            display: flex;
            width: 100%;
            .std_img {
                overflow: hidden;
                border-radius: 10px;
                width: 70px;
                img {
                    width: 100%;
                }
            }
        }
        .std_info {
            padding-top: 0;
            padding-left: 25px;
            h3 {
                font-size: 18px;
                color: #444;
                + p {
                    font-size: 14px;
                    color: #c3c3c3;
                    margin-bottom: 10px;
                }
            }
            .info_std_item {
                i {
                    font-size: 16px;
                    color: #999;
                    margin-right: 5px;
                }
                color: #666;
                font-weight: bold;
                margin-right: 40px;
            }
        }
        .std_action {
            a {
                &:focus {
                    border: none;
                    outline: none;
                }
            }
            margin-left: auto;
            margin-right: 5px;
            margin-top: 30px;
            i {
                font-size: 30px;
                margin-left: 10px;
            }
        }

        .extra_actions {
            i {
                font-size: 30px;
            }
            padding: 5px 10px 0 0;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
        }
        .chip_info {
            font-size: 13px;
            display: inline-block;
            background-color: rgba(44, 114, 197, 0.1);
            border: 1px solid rgba(44, 114, 197, 0.3);
            border-radius: 3px;
            padding: 3px 10px;
            color: rgba(44, 114, 197, 1);
        }
    }
</style>