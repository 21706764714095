<template>
  <div>
    <div id="chart">
      <apexchart type="bar" :options="options" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    options: {},
    series: [
      {
        data: [21, 22, 10, 28, 16, 21, 13, 30],
        chart: {
          toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        },
        }
      },
    ],
    // series: [44, 55, 41, 17, 15],
  }),
};
</script>