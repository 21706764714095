import Rest from '@/library/Rest'

export const getSubjects = ({commit, state}, payload) => {
    return new Promise((resolve, reject) => {

        //reset state
        state.items = [];
        state.selected = '';

        if (!payload || !payload.grade) {
            return;
        }

        state.fetching = true;

        let endpoint = '/api/subject?rowsPerPage=25&descending=true&grade=' + payload.grade;

        Rest().get(endpoint).then(({data}) => {
            state.items = data.data;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};