import Rest from '@/library/Rest'
import cookie from 'js-cookie';
import auth from '@/library/Auth';
import Storage from '@/library/Storage';

export const getPendingPayment = ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
        state.items = [];
        state.pendingInvoice = {};
        state.fetching = true;
        state.totalPaymentAmount = 0;
        state.paymentAlertModel = false;
        // if (!['accountant', 'administrator','guardian'].includes(auth.getRole())) return;

        let endpoint = '/api/payment-alert-status';
        const remind_later_flag = cookie.get('_al_ch_for');
        if (remind_later_flag) {
            endpoint += '?reminder_later_flag=' + remind_later_flag;
        }
        // if (!cookie.get('_payment_alert')) {
        if (!Storage.sessionGet('_payment_alert')) {
            Rest().get(endpoint).then(({ data }) => {
                if (!Object.keys(data).length) return;
                // var inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
                var midnight = new Date();
                midnight.setHours(23,59,59,0)
                // cookie.set("_payment_alert", data, { expires: midnight });
                Storage.sessionSet("_payment_alert", JSON.stringify(data), { expires: midnight });
                if (data.show_alert === true)
                    state.paymentAlertModel = true;
                state.totalPaymentAmount = data.amount;
    
                state.pendingInvoice = data;
    
    
                if (data.action_type === 'success' && data.is_expired === false) {
                    state.alertMessage = `Your subscription to eAcademyNepal is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
                } else if (data.action_type === 'warning' && data.is_expired === false) {
                    state.alertMessage = `Your subscription to eAcademyNepal is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
                } else if (data.is_expired === false) {
                    state.alertMessage = `CRITICAL ALERT!! Your subscription to eAcademyNepal is expiring today (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
                } else if (data.is_expired === true) {
                    state.alertMessage = `CRITICAL ALERT!! Your subscription to eAcademyNepal has been expired. (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
                }
    
                resolve(data)
            }).catch(err => {
                reject(err);
            }).finally(() => {
                state.fetching = false;
            });
        }else{
            // var data = JSON.parse(cookie.get('_payment_alert'))
            var data = JSON.parse(Storage.sessionGet('_payment_alert'))
            if (data.show_alert === true)
                state.paymentAlertModel = true;
            state.totalPaymentAmount = data.amount;
            state.pendingInvoice = data;
            if (data.action_type === 'success' && data.is_expired === false) {
                state.alertMessage = `Your subscription to eAcademyNepal is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
            } else if (data.action_type === 'warning' && data.is_expired === false) {
                state.alertMessage = `Your subscription to eAcademyNepal is expiring at ${data.payment_date} i.e ${data.human}. Please renew in time. Thank you!!`;
            } else if (data.is_expired === false) {
                state.alertMessage = `CRITICAL ALERT!! Your subscription to eAcademyNepal is expiring today (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
            } else if (data.is_expired === true) {
                state.alertMessage = `CRITICAL ALERT!! Your subscription to eAcademyNepal has been expired. (${data.payment_date} i.e ${data.human}). Please renew. Thank you!!`;
            }

            resolve(data)
        }
        
    });
};

export const updateReminder = ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {

        if (!['accountant', 'administrator'].includes(auth.getRole())) return;

        let endpoint = '/api/payment-alert-later/' + payload.id;

        Rest().put(endpoint, payload).then(({ data }) => {
            state.paymentAlertModel = false;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};