<template>
  <div class="icon_header_sub">
    <div class="icon-con">
      <v-icon color="error">info</v-icon>
    </div>
    <div class="text-con">
      <h2>Whats new?</h2>
      <p class="text-light">{{ sub }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sub: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.icon_header_sub {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  //   justify-content: center;
  align-items: center;
  .icon-con {
    font-size: 18px;
    padding: 0 10px;
  }
  .text-con {
    padding-top: 5px;
    h2 {
      color: #555;
      font-size: 18px;
    }
    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }
}
</style>
