import Rest from '@/library/Rest'

export const newEnrollStudentCount = ({commit, state}, payload) => {
    return new Promise((resolve, reject) => {
        // state.newEnrollCount--;
        // return;
        Rest().get('/api/get-new-admission-count').then(({data}) => {
            state.newEnrollCount = data.data;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};