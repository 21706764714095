<template>
    <v-btn
            :loading="loader"
            :disabled="loader"
            :outlined="outline"
            :color="color"
            @click="$emit('action')">
        <slot></slot>
        <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>cached</v-icon>
            </span>
        </template>
    </v-btn>
</template>
<script>
    export default {
        props: {
            loader: {
                type: Boolean
            },
            text: {
                default: 'Save',
                type: String
            },
            outline: {
                default: true,
            },
            color: {
                default: 'success'
            }
        }
    }
</script>