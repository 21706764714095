<template>
  <div
    :class="{
      'children-section small': true,
      is_selectable: this.result,
    }"
  >
    <div class="container">
      <div v-if="children.length < 1">
        <v-skeleton-loader
          elevation="0"
          v-if="true"
          v-bind="attrs"
          :loading="true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          elevation="0"
          v-if="true"
          v-bind="attrs"
          :loading="true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </div>
      <div
        class="guardian-class"
        v-for="(value, index) in filteredChildren"
        :key="index"
        @click="selectChild(index)"
      >
        <div class="class-info" v-if="index < expand || result">
          <img :src="value.profile_image" alt="" />
          <!-- @click="studentResults(value, index), (sheet = true)" -->
          <div class="guardian-name">
            <h1>{{ value.full_name }}</h1>
            <p>
              {{ value.academic.enroll_code }}&nbsp;/&nbsp;{{
                value.academic.grade
              }}&nbsp;/&nbsp;{{ value.academic.section }} &nbsp; / &nbsp;{{
                value.academic.roll
              }}
            </p>
          </div>
        </div>
        <!-- @click="studentResults(value, index), (sheet = true)" -->
        <div class="class-icon" v-if="index < expand && !result">
          <a>
            <i class="fas fa-chevron-right"></i>
          </a>
        </div>
        <div class="class-icon" v-if="result">
          <v-radio-group v-model="array_key">
            <v-radio :value="index" color="success"></v-radio>
          </v-radio-group>
        </div>
      </div>

      <div
        class="guardian-expand"
        @click="expand = children.length"
        v-if="children.length !== expand && result === false"
      >
        <a>
          <h3>{{ children_data_length }} More (Click to Expand)</h3>
        </a>
        <a><i class="fas fa-ellipsis-h"></i></a>
      </div>
      <div
        class="guardian-expand"
        @click="expand = 2"
        v-if="
          children.length == expand &&
          children.length > 1 &&
          children_data_length > 0
        "
      >
        <a>
          <h3>Hide {{ children_data_length }} More (Click to Contract)</h3>
        </a>
        <a><i class="fas fa-ellipsis-h"></i></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    result: {
      default: false,
    },
    reset_data: {
      default: false,
    },
  },
  data: () => ({
    filteredChildren: [],
    hasPermission: false,
    expand: 2,
    children_data_length: null,
    children: [],
    array_key: "",
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  watch: {
    expand: function (v) {
      this.showHideChildren(v);
    },
    array_key: function (v) {
      // setTimeout(() => {
      this.$emit("setArrayKey", this.filteredChildren[v]);
      // }, 5000);
    },
    reset_data: function (v) {
      if (v) {
        this.resetData();
      }
    },
  },
  mounted() {
    this.$rest
      .get("/api/guardian-children")
      .then(({ data }) => {
        if (data.data.length == 1) {
          this.expand = 1;
          this.children = data.data;
        }
        if (data.data.length >= 2) {
          setTimeout(() => {
            this.children_data_length = data.data.length - this.expand;
            this.children = data.data;
            this.showHideChildren(this.result === true ? data.data.length : 2);
          }, 2000);
        }
      })
      .then((e) => {});
  },
  methods: {
    resetData() {
      this.array_key = "";
    },
    selectChild(index) {
      this.array_key = index;
    },
    showHideChildren(numberofchildern) {
      let filterd_array = [];
      for (let step = 0; step < numberofchildern; step++) {
        filterd_array.push(this.children[step]);
      }
      this.filteredChildren = filterd_array;
    },
  },
};
</script>
<style lang="scss">
.eac-add-button {
  .v-chip__content {
    cursor: pointer;
  }
}
</style>
