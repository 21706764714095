<template>
  <div class="transaction" @click="$emit('click')">
    <div class="transaction-content">
      <div class="transaction-details">
        <h1>{{ title }}</h1>
        <h4>{{ info }}</h4>
      </div>
    </div>
    
    <div >
      <v-progress-circular
      :rotate="360"
      :size="70"
      :width="10"
      :value="percentage"
      color="primary"
    >
      {{percentage}}
    </v-progress-circular>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
    percentage: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      default: "far fa-clock",
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
