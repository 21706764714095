const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
const year = parseInt(nd.getYear()) + 1;

export default {
    search: {
        filters: {
            grade: '',
            section: '',
            // admission_from: year + "-01-01",
            // admission_to: year + "-12-31",
            admission_from: "",
            admission_to: ""
        },
        grades: [],
        sections: []
    },
}