<template>
  <p style="margin-bottom:0">
    {{ paragraph.substr(0, textLimit) }}
    <span v-if="paragraph.length > limit"
      ><span v-if="paragraph.length > textLimit">...</span>
      <small v-if="paragraph.length > textLimit"
        ><a @click.prevent="toggler('more')" href=""><u>See More</u></a></small
      >
      <small v-else
        ><a href="" @click.prevent="toggler('less')"><u>See Less</u></a></small
      ></span
    >
  </p>
</template>
<script>
export default {
  props: {
    paragraph: { required: false, type: String },
    limit: { default: 100 },
  },
  data: () => ({
    toggle: "see-more",
    textLimit: 0,
  }),
  mounted() {
    this.textLimit = this.limit;
  },
  methods: {
    toggler(type = "more") {
      if (type === "more") {
        this.textLimit = this.paragraph.length;
      } else {
        this.textLimit = this.limit;
      }
    },
  },
};
</script>
<style lang="scss">
.eac-add-button {
  .v-chip__content {
    cursor: pointer;
  }
}
</style>
