<template>
  <section class="guardian-page">
    <div class="container">
      <div class="guardian-box">
        <div class="guardian-head">
          <div class="guardian-content">
            <div class="icon-box">
              <div class="row">
                <div class="col-6">
                  <div class="guardian-icon">
                    <div class="img-circle">
                      <img :src="$auth.user().profile_image" alt="" />
                    </div>
                    <div class="guardian-info">
                      <a href=""
                        ><h5>{{ $auth.loggedInRole().name }}</h5></a
                      >
                      <h1>{{ $auth.user().full_name }}</h1>
                      <h3>{{ $auth.user().email }}</h3>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="guardian-icon-pt-2">
                    <a href=""><i class="fas fa-user"></i></a>
                    <a href=""><i class="fas fa-power-off"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="guardian-features">
          <div class="features">
            <div class="fees">
              <div class="fees-icon">
                <a href=""> <i class="fas fa-receipt"></i></a>
              </div>
              <div class="fees-text">
                <h2>Fees</h2>
              </div>
            </div>

            <div class="sms">
              <div class="sms-icon">
                <a href=""><i class="fas fa-comment-alt"></i></a>
              </div>
              <div class="sms-text">
                <h2>SMS</h2>
              </div>
            </div>

            <div class="transaction">
              <div class="transaction-icon">
                <a href=""><i class="fas fa-money-bill-alt"></i></a>
              </div>
              <div class="transaction-text">
                <h2>Transaction</h2>
              </div>
            </div>

            <div class="live-class">
              <div class="live-class-icon">
                <a href=""> <i class="fas fa-video"></i></a>
              </div>
              <div class="live-class-text">
                <h2>Live Class</h2>
              </div>
            </div>
          </div>
        </div>

        <div class="guardian-section">

            <div
              class="guardian-class"
              v-for="(value, index) in childrens"
              :key="index"
            >
              <div class="class-info" v-if="index < expand">
                <img :src="value.profile_image" alt="" />
                <div
                  class="guardian-name"
                  @click="studentResults(value, index), (sheet = true)"
                >
                  <h1>{{ value.full_name }}</h1>
                  <p>
                    {{ value.academic.enroll_code }}/{{ value.academic.grade }}/{{
                      value.academic.section
                    }}
                    / {{ value.academic.roll }}
                  </p>
                </div>
              </div>
              <div
                class="class-icon"
                @click="studentResults(value, index), (sheet = true)"
                v-if="index < expand"
              >
                <a>
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div
              class="guardian-expand"
              @click="expand++"
              v-if="childrens.length !== expand"
            >
              <a>
                <h3>{{ children_data_length }} More (Click to Expand)</h3>
              </a>
              <a><i class="fas fa-ellipsis-h"></i></a>
            </div>
        </div>

        <div class="guardian-statement">
          <div class="row">
            <div class="col-lg-4">
              <div class="statement-content">
                <img src="/images/files.png" alt="" />
                <h1>Statement</h1>
                <h6>Search Reports, statement stc.</h6>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="statement-content">
                <img src="/images/dollar.png" alt="" />
                <h1>Fees</h1>
                <h6>Search Reports.</h6>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="statement-content-fade">
                <img src="/images/presentation.png" alt="" />
                <h1>Courses</h1>
                <h6>List of available online course.</h6>
                <div class="fade-background"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ====================== Buttom Sheet Started ===================== -->
      <div>
        <v-bottom-sheet v-model="sheet" inset>
          <v-sheet class="text-center" height="200px">
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Filter Exam</span>
              </v-card-title>
              <v-card-title class="title pb-0">
                <v-flex xs12>
                  <v-select
                    :items="exams"
                    class="pa-0"
                    label="Exam"
                    v-model="selected_students.exam_id"
                    outlined
                    dense
                  />
                </v-flex>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" outlined @click="sheet = !sheet"
                  >Close</v-btn
                >
                <v-btn color="success" outlined @click="filterResult"
                  >Filter</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </div>
      <!-- =====================  Buttom Sheet Ended =====================  -->
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
export default {
  data: () => ({
    childrens: [],
    sheet: false,
    exams: [],
    selected_students: {
      exam_id: "",
    },
    expand: 2,
    children_data_length: "",
  }),
  mounted() {
    this.fetchGuardianChildern();
    this.getExams();
  },
  methods: {
    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    fetchGuardianChildern() {
      this.$rest
        .get("/api/guardian-children")
        .then(({ data }) => {
          this.children_data_length = data.data.length - this.expand;
          this.childrens = data.data;
        })
        .then((e) => {});
    },
    studentResults(value, index) {
      this.selected_students.enroll_id = value.academic.enroll_id.toString();
      this.selected_students.enrollCode = value.academic.enroll_code.toString();
    },
    filterResult() {
      if (this.selected_students.exam_id == "") {
        return this.$events.fire("notification", {
          message: "Please select Exam ",
          status: "error",
        });
      }
      this.sheet = false;
      this.$router.push({
        name: "exam-result",
        params: {
          examId: this.selected_students.exam_id,
          enrollId: this.selected_students.enroll_id,
          enrollCode: this.selected_students.enrollCode,
        },
      });
    },
  },
};
</script>

