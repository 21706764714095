import Rest from '@/library/Rest'

export const getGrades = ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {

        if (!payload.inside) {
            state.section.items = [];
            state.section.selected = '';
            state.grade.selected = '';
        }


        let endpoint = '/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true';
        if (payload && payload.filter) {
            endpoint += '&filter=true';
        }
        if (payload && payload.batchId) {
            endpoint += `&batchId=${payload.batchId}`;
            if (!payload.inside)
                state.grade.items = [];
        }
        if (payload && payload.companyId) {
            endpoint += `&school_explicit=${payload.companyId}`
        }
        if ((state.grade.items.length < 1 && state.grade.fetching === false) || payload.inside === true) {
            if (!payload.inside) state.grade.fetching = true;
            Rest().get(endpoint).then(({ data }) => {
                state.grade.items = data.data;
                resolve(data)
            }).catch(err => {
                reject(err);
            }).finally(() => {
                if (!payload.inside)
                    state.grade.fetching = false;
            });
        }
    });
};