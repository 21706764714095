/** Lazy Load View **/
function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/onlineRegistration/_components/${view}.vue`)
}

let routes = [
	{
		path: 'online-registration',
		name: 'online-registration',
		component: loadView('index'),
		meta: {
			auth: true
		}
	}
];

export default routes;