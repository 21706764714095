/** Lazy Load View **/
function loadView(view) {
	return () => import(`@/modules/aio/_components/${view}.vue`)
}

let routes = [
	{
		path: 'aio',
		name: 'aio',
		component: loadView('index'),
		meta: {
			auth: true
		}
	}
];

export default routes;