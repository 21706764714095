/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/students/_components/${view}.vue`)
}

let routes = [{
    name: 'search-students',
    path: '/search-students',
    component: loadView('Search/index'),
    meta: {
        auth: true,
        permission: 'student-read'
    }
}, {
    name: 'students',
    path: '/students',
    component: loadView('Students/index'),
    meta: {
        auth: true,
        permission: 'student-read'
    }
}, {
    name: 'students-left',
    path: '/students-left',
    component: loadView('StudentLeft/index'),
    meta: {
        auth: true,
        permission: 'student-read'
    }
}, {
    path: '/student/:id',
    component: () => import(`@/modules/students/_components/Profile/index.vue`),
    meta: {
        auth: true
    },
    children: [
        {
            path: '/',
            name: 'std-profile',
            component: () => import(`@/modules/students/_components/Profile/pages/home.vue`),
            meta: {
                auth: true
            }
        },
        {
            path: 'enroll',
            name: 'std-enroll',
            component: () => import(`@/modules/students/_components/Profile/pages/enroll.vue`),
            meta: {
                auth: true
            }
        },
        {
            path: 'address-transport',
            name: 'std-transport',
            component: () => import(`@/modules/students/_components/Profile/pages/transport.vue`),
            meta: {
                auth: true
            },
        }
    ]
}, {
    name: 'admit',
    path: '/admit',
    component: loadView('Admit/index'),
    meta: {
        auth: true,
        permission: 'student-create'
    }
}, {
    name: 'promote',
    path: '/promote',
    component: loadView('Promote/index'),
    meta: {
        auth: true,
        permission: 'student-promote'
    }
},{
    name:'bulk-admit',
    path:'/bulk-admission',
    component:loadView('BulkAdmission/index'),
    meta:{
        auth:true,
        permission:'student-create'
    }
}
];

export default routes;