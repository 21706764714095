<template>
  <v-dialog
    v-if="hasPermission && showButton"
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        x-small
        :outlined="outlined === undefined ? true : outlined"
        color="error"
        class="ml-1 mt-1 mb-1"
        v-on="on"
      >
        <v-icon x-small>{{ icon || "clear" }}</v-icon
        ><strong class="ml-1">Delete</strong>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title pa-3 red white--text">
        <v-icon class="mr-2 white--text">warning</v-icon>
        Please, wait!
      </v-card-title>
      <v-card-text class="ma-0">
        <!-- <h3>{{message||'Are you sure you want to delete this item?'}}</h3>
                <v-divider></v-divider> -->

        <br />
        <alert-message
          extra-small
          type="error"
          title="Please wait !!"
          :message="message || 'Make sure you are fully aware what you are doing. You can always contact us if you are confused or need any help.'"
          ></alert-message>
          <!-- :message="'Make sure you are fully aware what you are doing. You can always contact us if you are confused or need any help.'" -->
        <p class="ml-0 mr-0 mb-0 pb-0 mt-3">
          {{ description }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          small
          @click="(dialog = false), $emit('disagree')"
          >Cancel</v-btn
        >
        <v-btn
          v-if="!showLoader"
          color="error"
          text
          small
          @click="( dialogueClose === false ? dialog= false : ''), $emit('agree')"
          >Delete
        </v-btn
        >
        <v-btn
          v-if="showLoader"
          :loading="true"
          outlined
          color="success"
        >
        <slot></slot>
          <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>cached</v-icon>
              </span>
          </template>
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isCurrentBatch } from "../library/helpers";

export default {
  // props: [
  //   "icon",
  //   "permission",
  //   "message",
  //   "description",
  //   "outlined",
  //   "disabled",
  //   "onCurrentBatch",
  // ],
  props: {
    icon: { required: false },
    permission: { required: false },
    message: { required: false },
    description: { required: false },
    color: { required: false },
    outlined: { required: false },
    disabled: { required: false, default: false },
    onCurrentBatch: { required: false, default: true },
    showLoader: { required: false, default:false },
    //once impletment in all the component dialogueClose props and its related works can be removed
    dialogueClose: { required: false , default: false}
  },
  data: () => ({
    dialog: false,
    hasPermission: false,
    // onCurrentBatch: true,
  }),
  computed: {
    showButton() {
      if (this.$auth.isAdmin() || this.$auth.isSuper() ) {
        return true;
      }
      if (this.onCurrentBatch) {
        return this.isCurrentBatch();
      }
      return true;
    },
  },
  mounted() {
    if (this.permission)
      // this.hasPermission = this.$permissions.checkPermissions(this.permission);
      this.hasPermission = this.$auth.can(this.permission);

    // if (this.permission) {
    //     const requiredPermissions = this.permission.split('|');
    //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
    // }
  },
  methods: {
    isCurrentBatch,

    reset() {
      this.dialog = false;
    }
  },
};
</script>
