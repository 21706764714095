<template>
  <v-hover v-if="hasPermission">
    <v-btn
      :disabled="disabled"
      :outlined="outlined === undefined ? true : outlined"
      @click="$emit('action')"
      x-small
      :color="color"
    >
      <v-icon x-small>{{ icon }}</v-icon>
      <strong class="ml-1">{{ label }}</strong>
    </v-btn>
  </v-hover>
</template>
<script>
export default {
  props: {
    icon: {
      default: "fullscreen",
    },
    permission: {
      default: false,
    },
    color: {
      default: "primary",
    },
    label: {
      default: "View",
    },
    outlined: {
      default: true,
    },
    disabled: {
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    hasPermission: false,
  }),
  mounted() {
    if (this.permission) this.hasPermission = this.$auth.can(this.permission);
  },
};
</script>
