<template>
  <div class="statement-content">
    <div>
      <img
        src="https://img.icons8.com/color/96/000000/noticeboard.png"
        alt=""
      />
      <h1>{{ title }}</h1>
      <h6>{{ info }}</h6>
      <div class="fade-background"></div> 
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
