import ls from './Storage'
import encryption from './Encryption'
import cookie from 'js-cookie'
import {slugifyText} from './helpers'


var jwtDecode = require('jwt-decode');

let auth = new (function () {
	let permissions = () => {
		try {
			return encryption.decryptObject(ls.get("_permissions"));
		} catch (e) {
			return null;
		}
	};

	this.set = (payload) => {
		ls.set("_token", payload.token);
		ls.set("_user", payload.user);
		ls.set("_company", payload.company, { expires: 365 });
		cookie.set("_cl", this.company("logo"), { expires: 365 });
		cookie.set("_batch", payload.batch, { expires: 365 });
	};

	this.setUser = (user) => {
		ls.set("_user", user);
	};

	this.get = () => {
		let lib = ls.get("_token");
		return `${lib}`;
	};

	this.setPermission = (permissions) => {
		ls.set("_permissions", permissions);
	};

	this.setRole = (role) => {
		ls.set("_role", role);
	};

	this.getRole = (role) => {
		return ls.get("_role", role);
	};

	this.getLoggedIn = () => {
		let loggedDate = ls.get("_lg_in" + "_" + this.getRole());
		if (loggedDate) {
			loggedDate = new Date(loggedDate);
			let hours = loggedDate.getHours();
			let minutes = loggedDate.getMinutes();
			let date = loggedDate.getDate();
			let months = loggedDate.getMonth() + 1;
			if (hours < 10) hours = "0" + hours;
			if (months < 10) months = "0" + months;
			if (date < 10) date = "0" + date;
			return `${loggedDate.getFullYear()}-${months}-${date} ${hours}:${minutes}`;
		}
		return "Not Available";
	};

	this.loggedInRole = () => {
		let myRoleSlug = ls.get("_role");
		let myRole = {};
		this.user()?.roles.map(function (role) {
			if (role.slug === myRoleSlug) myRole = role;
		});
		return myRole;
	};

	this.user = () => {
		try {
			if (!ls.get("_user") && cookie.get("_user")) {
				this.$auth.setUser(cookie.get("_user"));
			}

			return encryption.decryptObject(ls.get("_user"));
		} catch (e) {
			return null;
		}
	};

	/**
   * pass role's slug to check if the current logged in user has that role or not.
   * @param role
   * @returns {boolean}
   */
	this.hasRole = (role) => {
		if (!role) return false;
		const { roles } = this.user();

		if (roles.length < 1) return false;
		let output = false;
		try {
			//try catch to break the loop.
			roles.map(function (item) {
				if (item.slug === role) {
					output = true;
					throw Error("Hello");
				}
			});
		} catch (e) {}
		return output;
	};

	/**
   * Get the current batch saved from the logged in user's response
   * @returns {*}
   */
	this.getCurrentBatch = () => {
		try {
			return encryption.decryptObject(cookie.get("_batch"));
		} catch (e) {
			return null;
		}
	};

	/**
   * Check authenticated.
   * @returns {boolean}
   */
	this.authenticated = () => {
		let data = ls.get("_token");
		if (data === undefined || !permissions()) return false;
		try {
			var decoded = jwtDecode(data);
			data = encryption.decrypt(decoded.sub);
			return !isNaN(data);
		} catch (e) {
			return false;
		}
	};

	/**
   * Get the current batch saved from the logged in user's response
   * @returns {*}
   */
	this.getCurrentBatch = () => {
		try {
			return encryption.decryptObject(cookie.get("_batch"));
		} catch (e) {
			return null;
		}
	};

	/**
   * Check authenticated.
   * @returns {boolean}
   */
	this.authenticated = () => {
		let data = ls.get("_token");
		if (data === undefined || !permissions()) return false;
		try {
			var decoded = jwtDecode(data);
			data = encryption.decrypt(decoded.sub);
			return !isNaN(data);
		} catch (e) {
			return false;
		}
	};

	this.permissions = () => permissions();

	this.can = (p) => {
		if (typeof p === "boolean") return p;

		if ([undefined, null, ""].includes(p)) return false;
		let sign = "";

		if (!permissions()) return false;

		if (p.includes("&")) sign = "&";
		else if (p.includes("|")) sign = "|";
		else return permissions().indexOf(p) !== -1;

		p = p.replace(/\s/g, "").split(sign);
		if (!p.length) return false;
		if (sign === "&") {
			return p.every((r) => {
				return permissions().indexOf(r) !== -1;
			});
		} else {
			return p.some((r) => permissions().indexOf(r) !== -1);
		}
	};

	this.clear = () => {
		ls.clear();
	};

	this.company = (payload) => {
		try {
			const company = encryption.decryptObject(ls.get("_company"));
			if (!payload) return company;

			return company[payload] || {};
		} catch (e) {
			return {};
		}
	};

	/**
   * Function helps to get the services subscribed by company
   * or check if the provided services is available for the logged in user's company.
   * @param val
   * @returns {boolean}
   */
	this.getServices = (val, slugified = false) => {
		if (!val) return false;
		if (val === true) return true;
		const company = encryption.decryptObject(ls.get("_company"));
		let services = [];
		if (company.services) {
			services = company.services.map((res) => {
				return slugified === true ? slugifyText(res.name) : res.name;
			});
			if (services.includes(val) || val === true) return true;
			else return false;
		} else {
			return false;
		}
	};

	/**
   * Check if the logged in user is eAcademy administrator
   * @returns {boolean}
   */
	this.isAdmin = () => {
		const user = this.user();
		return user.ut === "admin";
	};

  this.isSuper = () => {
    const user = this.user();
    return this.getRole() === "super" || user.ut === "super";
  };
  
  this.isAuthExpire = () => {
    let auth_expiry_date = this.user().auth_expiry;
    if(auth_expiry_date){
      auth_expiry_date = auth_expiry_date.split("-")
      auth_expiry_date = auth_expiry_date[1]+'/'+auth_expiry_date[2]+"/"+auth_expiry_date[0];
  
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      today = mm + '/' + dd + '/' + yyyy;
  
  
      var d1 = new Date(today);  
      var d2 = new Date(auth_expiry_date);   
          
      var diff = d2.getTime() - d1.getTime();   
          
      var daydiff = diff / (1000 * 60 * 60 * 24);   
  
      if(daydiff === 0 || daydiff < 0) return false

    }
    return true
    // return auth_expiry_date;
  };
})();
// zalzala.khadka010@gmail.com
export default auth;
