<template>
  <!-- <v-card class="primary--border elevation-0" outlined > -->
  <v-card :class="!birthdayUse ? 'primary--border elevation-0' :''" outlined >
    <v-card-text class="pb-1">
      <alert-message
        type="warning"
        title="Service Charge Alert"
        message="SMS Rate is inclusive of 10% T.C. service charge and 13% Government Tax. Click Details to view the calculation info."
        extraSmall
      ></alert-message>
        <!-- message="SMS Rate is inclusive of 13% T.C. service charge and 13% Government Tax. Click Details to view the calculation info." -->
      <br />
      <v-data-table
        v-if="!birthdayUse"
        :loading="loading"
        hide-default-footer
        :items="[
          { text: 'Balance' },
          { text: 'Rate Per SMS' },
          { text: 'In Queue' },
        ]"
        :headers="[
          ...[
            { text: 'Head', align: 'left', sortable: false },
            { text: '#', align: 'right', sortable: false },
          ],
        ]"
      >
        <template v-slot:item="{ item }">
          <tr>
            <th>{{ item.text }}</th>
            <td class="text-right">
              <span v-if="item.text === 'Balance'">{{
                balance.currency()
              }}&nbsp;<small><a :href="sms_url" target="_blank"><u>(Recharge)</u></a></small>
              </span>
              <span v-else-if="item.text === 'Rate Per SMS'"
                ><strong>{{ ratePerSMS.currency() }}</strong>
                &nbsp;<small
                  ><a href=""><u>(Details)</u></a></small
                ></span
              >
              <span v-else-if="item.text === 'In Queue'">{{ smsQueue }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br />
    </v-card-text>
  </v-card>
</template>
<script>
import AlertMessage from "../AlertMessage.vue";

export default {
  components: { AlertMessage },
  props: {
    smsQueue: { required: true },
    balance: { required: true },
    ratePerSMS: { required: true },
    loading: { required: false, default: false },
    birthdayUse: {required:false, default:false},
  },
  data: () => ({
    hasPermission: false,
    sms_url:''
  }),
  computed: {},
  mounted() {
    if(process.env.VUE_APP_CONNECTION !== 'QA' && process.env.VUE_APP_CONNECTION !== 'LIVE' && process.env.VUE_APP_CONNECTION !== 'DEV'){
      this.sms_url = 'https://smssarara.com/app/?action=load-balance';
    }else{
      this.sms_url = "https://sms.sahakaryatech.com/app?action=load-balance"
    }
  },
  methods: {},
};
</script>
<style lang="scss">
.eac-add-button {
  .v-chip__content {
    cursor: pointer;
  }
}
</style>
