<template>
  <strike v-if="strike"><slot /></strike>
  <span v-else><slot /></span>
</template>
<script>
export default {
  props: {
    strike: { default: false },
  },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
<style lang="scss"></style>
