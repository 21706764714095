function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/learningMaterials/_components/${view}.vue`)
}

let routes = [
    {
        name: 'learning-materials',
        path: 'learning-materials/:gradeId?/:subjectId?/:syllabusId?',
        component: loadView('index'),
        meta: {
            auth: true, 
        }
    }
];

export default routes;