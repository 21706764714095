<template>
    <p class="text-danger" style="font-size:10px;line-height:1;margin-top:0;padding-top:0;">{{ showCurrentError }}</p> 
</template>

<script>
export default {
  props: {
    errorName: { required: false, default: "Invalid Data" },
    displayError: { required: false, default: "" },
  },
  data: () => ({
    error: "",
  }),
  computed: {
    showCurrentError() {
      let output = null;
      try {
        Object.keys(this.displayError.errors).forEach((key) => {
          if (key === this.errorName) {
            output = this.displayError.errors[key][0];

            throw Error();
          }
        });
      } catch {}
      return output;
    },
  },
 
};
</script>
