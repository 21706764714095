<template>
    <div>
        <v-select :disabled="!gradeId" v-bind:value="value" :loading="subject.fetching"
                  :items="subjectItems"
                  outlined dense
                  @change="setSubject"
                  class="pa-0" :label="label || 'Select subject'"/>
    </div>
</template>
<script>

    import {mapActions, mapState} from 'vuex';

    export default {
        props: {
            gradeId: {
                required: true
            },
            label: {},
            value: {}
        },
        data: () => ({
            hasPermission: false
        }),
        computed: {
            ...mapState(['subject']),
            subjectItems() {
                return this.subject.items.map((item) => {
                    return {value: item.id, text: item.name}
                });
            }
        },
        watch: {
            'gradeId'(grade) {
                if (grade)
                    this.getSubjects({grade});
            }
        },
        mounted() {
        },
        methods: {
            ...mapActions(['getSubjects']),
            setSubject(value) {
                this.$emit('input', value);
            }
        }


    }
</script>
<style lang="scss">

</style>