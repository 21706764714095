<template>
  <div
    class="not-found"
    :style="'width: ' + width + 'px;margin:auto;padding:20px 0;opacity:0.7;'"
  >
    <no-data></no-data>
    <h2 style="text-align: center; color: #555">{{ title }}</h2>
    <p style="text-align: center; color: #666">{{ text }}</p>
  </div>
</template>
<script>
import NoData from "./NoData";

export default {
  components: {
    NoData,
  },
  props: {
    title: {
      type: String,
      default: "No Data found",
    },
    text: {
      type: String,
    },
    width: {
      type: Number,
      default: 250,
    },
  },
};
</script>

<style>
</style>