<template>
  <div>
    <v-select
      :disabled="disabled"
      v-bind:value="value"
      :loading="fetching"
      :items="feeHeadList"
      @change="setGrade"
      outlined
      dense
      :multiple="multiple"
      class="pa-0"
      :label="label || 'Fee Head'"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    value: {},
    label: {},
    items: {},
    disabled: { default: false },
    multiple: { default: false },
    onlyCustomizable: { default: false },
    companyId: {},
    inside: { default: false },
    withAll: { default: false },
  },
  data() {
    return {
      feeHeadList: [],
      selectedGradeId: "",
      fetching: false,
    };
  },
  watch: {
    batchId(value) {
      this.fetchGrades();
    },
  },
  computed: {
    ...mapState(["grade"]),
  },
  mounted() {
    this.fetchFeeHeads();
  },
  methods: {
    ...mapActions(["getGrades"]),
    ...mapMutations(["SET_SECTIONS"]),
    setGrade(value) {
      if (this.inside) {
        this.selectedGradeId = value;
      } else {
        this.grade.grade.selected = value;
      }

      this.$emit("input", value);
      this.$emit("selectedGrade", this.selectedGrade);
    },
    fetchFeeHeads() {
      this.feeHeadList = [];
      this.fetching = true;
      this.$rest
        .get(`/api/fee-head?withCustomization=${this.onlyCustomizable}`)
        .then((res) => {
          this.feeHeadList = res.data.data.map((item) => {
            return { value: item.id, text: item.title };
          });
          this.feeHeadList = this.withAll
            ? [{ text: "All", value: "all" }, ...this.feeHeadList]
            : this.feeHeadList;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>
<style lang="scss"></style>
