<template>
    <div>
        <!--<v-select :disabled="disabled" v-bind:value="value" :loading="grade.grade.fetching"-->
        <!--:items="gradeItems"-->
        <!--@change="setGrade"-->
        <!--outlined dense-->
        <!--class="pa-0" :label="label || 'Select grade'"/>-->

        <v-select :multiple="true" @change="setValues"
                  :error-messages="inputError"
                  v-bind:value="value"
                  :items="itemList"
                  :label="label || 'Select'"
                  class="pt-0" outlined dense
                  :rules="[v => !!v || 'Field is required']">
            <v-list-item v-if="itemList.length>1" slot="prepend-item" ripple @click="toggle">
                <v-list-item-action>
                    <v-icon :color="1 > 0 ? 'indigo darken-4' : ''">
                        {{icon}}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-title>{{selectAllLabel||'Select All'}}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="itemList.length>1" slot="prepend-item" class="mt-2"></v-divider>
            <v-divider slot="append-item" class="mb-2"></v-divider>
        </v-select>
    </div>

</template>
<script>
    import {mapState, mapActions, mapMutations} from 'vuex'

    export default {
        // props: ['value', 'label', 'batchId', 'disabled'],
        props: {
            value: {},
            label: {},
            selectAllLabel:{},
            itemList: {
                required: true
            },
            inputError: {}
        },
        data() {
            return {}
        },
        watch: {
        },
        computed: {
            icon() {
                if (this.selectAllDays) return 'check_box';
                if (this.selectFewDays) return 'indeterminate_check_box';
                return 'add_box'
            }
        },
        mounted() {
            // this.fetchGrades();
        },
        methods: {
            setValues(value) {
                this.$emit('input', value);
            },
            toggle() {
                this.$nextTick(() => {
                    // this.value = [];
                    if (this.value.length === this.itemList.length) {
                        this.setValues([]);
                    } else {
                        const allSelected = this.itemList.map(item => {
                            return item.value;
                        });
                        this.setValues(allSelected);
                    }
                })
            }
        }
    }
</script>
<style lang="scss">

</style>