<template>
    <div class="invoice-payment" @click="$emit('click')">
      <!-- All -->
      <h2 @click = setInvoiceStatus(4)>
        <a>{{ info }}</a>
      </h2>
      <!-- Dues -->
      <h2 :class="{'due-payment':true, 'error':invoice_status == 0 ? true:false}" @click = setInvoiceStatus(0)> 
        <span :style="invoice_status == 0 ? 'color:white;cursor: pointer;':'color:black;cursor: pointer;'">{{info2}}</span>
      </h2>
      <!-- Partial Paid -->
      <h2 @click = setInvoiceStatus(3) :class="{
        'due-payment':true, 'warning':invoice_status == 3 ? true:false,
      }">
       <span :style="invoice_status == 3 ? 'color:white;cursor: pointer;':'color:black;cursor: pointer;'">{{ info3 }}</span> 
      </h2>
      <!-- Full Paid -->
      <h2 @click = setInvoiceStatus(1) :class="{'due-payment':true, 'success':invoice_status == 1 ? true:false}">
          <span :style="invoice_status == 1 ? 'color:white;cursor: pointer;':'color:black;cursor: pointer;'">
        {{ info4 }}
          </span>
      </h2>
    </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
    info2: {
      required: true,
      type: String,
    },
    info3: {
      required: true,
      type: String,
    },
    info4: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    invoice_status:0,
  }),
  watch: {},
  mounted() {},
  methods: {
    setInvoiceStatus(v){
      this.invoice_status = v
      this.$emit("setInvoiceStatus", v);
    }
  },
};
</script>
