function loadView(view) {
    return () => import(`@/modules/calendar/_components/${view}.vue`)
}

let routes = [
    {
        name: 'calendar',
        path: '/calendar',
        component: loadView('Calendar/index'),
        meta: {
            auth: true,
            permission: 'event-read'
        }
    },
    {
        name: 'event-list',
        path: '/event-list',
        component: loadView('Calendar/eventlist'),
        meta: {
            auth: true,
            permission: 'event-read'
        }
    },
    {
        name: 'personal',
        path: '/personal',
        component: loadView('Calendar/personalevent'),
        meta: {
            auth: true,
            permission: 'event-read'
        }
    },
   
];

export default routes;