<template>
  <div>
    <v-list-item-avatar>
      <img
        :src="src"
        alt="student_image"
        @click="preview = true"
        style="cursor: pointer"
      />
      <input
        type="file"
        style="display: none;"
        ref="image"
        accept="image/*"
        @change="onFilePicked"
      />
    </v-list-item-avatar>

    <v-dialog
      @keydown.esc="preview = false"
      v-model="preview"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="title pa-3 success white--text">
          <div style="margin-right:auto;">
            <v-icon class="mr-2 white--text">image</v-icon>
            {{ title || "Preview" }}
          </div>
          <div
            @click="onPickFile()"
            style="margin-left:auto;float:right;cursor:pointer;"
          >
            <v-icon color="white">upload</v-icon>
          </div>
          <div
            @click="preview = false"
            style="margin-left:20px;float:right;cursor:pointer;"
          >
            <a :href="src" target="_blank" download
              ><v-icon color="white">download</v-icon></a
            >
          </div>
          <div
            @click="preview = false"
            style="margin-left:20px;float:right;cursor:pointer;"
          >
            <v-icon color="white">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="ma-0 pb-4 pt-3">
          <img style="width:100%;margin-top:10px;" :src="src" alt="" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cropperImg.cropDialog" persistent max-width="600">
      <v-card>
        <vue-cropper
          ref="cropper"
          :guides="true"
          :view-mode="2"
          drag-mode="crop"
          :auto-crop-area="0.5"
          :min-container-width="100"
          :min-container-height="100"
          :background="false"
          :min-crop-box-width="200"
          :max-crop-box-width="200"
          :min-crop-box-height="200"
          :max-crop-box-height="200"
          :aspect-ratio="1"
          :rotatable="true"
          :src="cropperImg.imgSrc"
          alt="Source Image"
          :img-style="{ width: '500px', height: '500px' }"
        >
        </vue-cropper>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="cropperImg.cropDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="green darken-1" @click="cropImage">Crop</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import Form from "@/library/Form";

export default {
  components: { VueCropper },
  props: {
    src: {},
    title: {},
    userId: {},
  },
  data() {
    return {
      preview: false,
      cropperImg: {
        cropDialog: false,
        imgSrc: "",
        cropImg: "",
        uploadCropped: {},
        name: null,
        uploading: false,
      },
      uploadProfile: new Form({ image: "" }, "/api/student"),
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    onFilePicked(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropperImg.imgSrc = event.target.result;
          this.cropperImg.name = file.name;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.cropperImg.cropDialog = true;
    },

    onPickFile() {
      this.$refs.image.click();
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const canvas = this.$refs.cropper.getCroppedCanvas();
      this.cropperImg.cropImg = canvas.toDataURL();
      let vm = this;
      canvas.toBlob(
        function(blob) {
          blob.name = "image";
          vm.cropperImg.uploadCropped = blob;
          vm.cropperImg.cropDialog = false;

          //upload to server.
          if (Object.keys(vm.cropperImg.uploadCropped).length > 0) {
            let formData = new FormData();
            vm.uploadProfile.image = vm.cropperImg.uploadCropped;
            vm.uploadProfile.endpoint =
              "/api/student/" + vm.userId + "/profile";
            vm.uploadProfile.fireFetch = false;
            vm.cropperImg.uploading = true;
            vm.uploadProfile
              .store()
              .then(() => {
                vm.$emit("onUpload");
              })
              .finally(() => {
                vm.cropperImg.uploading = false;
              });
          }
        },
        "image/jpeg",
        0.3
      );
    },
  },
};
</script>
<style lang="scss"></style>
