<template>
  <div style="width: 100%">
    <template v-for="(document, key) in documents">
      <div class="uploaded-file-preview" :key="key">
        <div class="icon-holder">
          <v-icon size="36" color="white">{{ getIcons(document.name) }}</v-icon>
        </div>
        <div class="file-info-holder">
          <h4>{{ typeFormat(document.name) }}</h4>
          <p>{{ document.name.substr(0, 20) }}</p>
          <v-icon @click="deleteImage(key)">close</v-icon>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    documents: {
      required: true,
      type: Array,
    },
    label: {},
  },
  data: () => ({
    //https://www.computerhope.com/issues/ch001789.htm
    extensionList: {
      pdf: "picture_as_pdf",
      doc: "WORD",
      docx: "WORD",
      xls: "border_all",
      xlsx: "border_all",
      ppt: "slideshow",
      pptx: "slideshow",
      txt: "subject",
      png: "perm_media",
      jpg: "perm_media",
      jpeg: "perm_media",
      gif: "perm_media",
    },
  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    typeFormat(fileName) {
      const fileParts = fileName.split(".");
      const extension = fileParts[fileParts.length - 1];
      const extensionList = {
        pdf: "PDF",
        doc: "WORD",
        docx: "WORD",
        xls: "EXCEL",
        xlsx: "EXCEL",
        ppt: "POWERPOINT",
        pptx: "POWERPOINT",
        txt: "TEXT",
        png: "IMAGE",
        jpg: "IMAGE",
        jpeg: "IMAGE",
        gif: "IMAGE",
      };

      if (extensionList[extension.toLowerCase()]) {
        return extensionList[extension.toLowerCase()];
      }
      return "UNKNOWN FILE FORMAT";
    },

    deleteImage(index) {
      this.documents.splice(index, 1);
    },
    getIcons(name) {
      const fileParts = name.split(".");
      const extension = fileParts[fileParts.length - 1];

      if (this.extensionList[extension.toLowerCase()]) {
        return this.extensionList[extension.toLowerCase()];
      }
      return "error";
    },
  },
};
</script>
<style lang="scss" scoped>
.uploaded-file-preview {
  /*display: flex;*/
  /*min-height: 40px;*/
  width: 100%;
  /*border-radius: 15px;*/
  /*justify-content: center;*/
  align-items: center;

  overflow: hidden;
  margin-bottom: 15px;
  .icon-holder {
    width: 20%;
    padding: 5px 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    float: left;
    flex: 1;
    /*height: 40px;*/
    background-color: #1976d2 !important;
    border: 1px solid #1976d2;

    text-align: center;
  }
  .file-info-holder {
    border: 1px solid #ccc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    border-left: none;
    padding: 5px 15px;
    color: #777;
    width: 80%;
    float: right;
    position: relative;
    p {
      margin-bottom: 0;
      line-height: 1;
    }
    i,
    button {
      position: absolute;
      right: 5px;
      font-size: 18px;
      color: #666;
      top: 5px;
      cursor: pointer;
    }
  }
}
</style>
