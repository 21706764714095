/** Lazy Load View **/
function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/modules/billing/_components/${view}.vue`
    );
}

let routes = [
  {
    name: 'fee-heads',
    path: '/fee-heads',
    component: loadView('FeeHeads/index'),
    meta: {
      auth: true,
      permission: 'fee-heads-read',
    },
  },
  {
    name: 'fee',
    path: '/fee',
    component: loadView('Fee/index'),
    meta: {
      auth: true,
      permission: 'fee-read',
    },
  },
  {
    name: 'fee-offer',
    path: '/fee-offer',
    component: loadView('FeeOffer/index'),
    meta: {
      auth: true,
      permission: 'fee-offer-read',
    },
  },
  {
    name: 'bill-generate',
    path: '/monthly-bill-generate',
    component: loadView('ClasswiseBillGenerate/indexV2'),
    meta: {
      auth: true,
      permission: 'bill-generate-class-wise',
    },
  },
  {
    name: 'customized-fee',
    path: '/customized-fee',
    component: loadView('CustomizedFee/index'),
    meta: {
      auth: true,
      permission: 'fee-heads-read',
    },
  },
  {
    name: 'billing-book-list',
    path: '/billing-book-list',
    component: loadView('BillingBookList/index'),
    meta: {
      auth: true,
      permission: 'bill-generate-class-wise',
    },
  },
  {
    name: 'fee-payment',
    path: '/fee-payment',
    component: loadView('FeePayment/indexV2'),
    meta: {
      auth: true,
      permission: 'receipt-read',
    },
  },
  {
    name: 'quick-fee-payment',
    path: '/quick-fee-payment',
    component: loadView('FeePayment/indexV2'),
    meta: {
      auth: true,
      permission: 'receipt-read',
    },
  },
  {
    name: 'fee-payment-V2',
    path: '/fee-payment/v2',
    component: loadView('FeePayment/indexV2'),
    meta: {
      auth: true,
      permission: 'receipt-read',
    },
  },
  {
    name: 'statement',
    path: '/statement',
    component: loadView('Statement/index'),
    meta: {
      auth: true,
      permission: 'billing-report-statement',
    },
  },
  {
    name: 'statementold',
    path: '/statement-old',
    component: loadView('Statement/indexOld'),
    meta: {
      auth: true,
      permission: 'billing-report-statement',
    },
  },
  {
    name: 'sod',
    path: '/sod',
    component: loadView('Sod/index'),
    meta: {
      auth: true,
      permission: 'sod-read',
    },
  },
  {
    name: 'sod-statement',
    path: '/sod-statement/:sod',
    component: loadView('Sodstatement/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'receipt-report',
    path: '/receipt-report',
    component: loadView('ReceiptReport/index'),
    meta: {
      auth: true,
      permission: 'receipt-read',
    },
  },
  {
    name: 'collection-due',
    path: '/collection-due',
    component: loadView('CollectionDue/index'),
    meta: {
      auth: true,
      permission: 'billing-report-collection',
    },
  },
  {
    name: 'fee-offer-report',
    path: '/fee-offer-report',
    component: loadView('FeeOfferReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'billing-to-account',
    path: '/billing-to-account',
    component: loadView('BillingToAccount/index'),
    meta: {
      auth: true,
      permission: 'bill-generate-verify',
    },
  },
  {
    name: 'previous-due',
    path: '/previous-due',
    component: loadView('PreviousDue/index'),
    meta: {
      auth: true,
      permission: 'due-read',
    },
  },
  {
    name: 'previous-due-report',
    path: '/previous-due-report',
    component: loadView('PreviousDueReport/index'),
    meta: {
      auth: true,
      permission: 'due-read',
    },
  },
  {
    name: 'bill-generate-report',
    path: '/bill-generate-report',
    component: loadView('BillGenerateReport/index'),
    meta: {
      auth: true,
      permission: 'due-read',
    },
  },
  // {
  //     name: 'advance-payment',
  //     path: '/advance-payment',
  //     component: loadView('AdvancePayment/index'),
  //     meta: {
  //         auth: true,
  //         permission: true
  //     }
  // },

  {
    name: 'billing-to-account-details',
    path: '/billing-to-account/:billLogId',
    component: loadView('BillingToAccountDetails/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'mapping',
    path: '/mapping',
    component: loadView('Mapping/index'),
    meta: {
      auth: true,
      permission: 'account-mapping-read',
    },
  },
  {
    name: 'fee-general-receipt',
    path: '/fee-general-receipt',
    // path:'/fee-general-receipt/:advance?'
    component: loadView('FeeGeneralReceipt/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'advance-payment',
    path: '/advance-payment',
    component: loadView('FeeGeneralReceipt/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'security-deposit',
    path: '/security-deposit',
    component: loadView('FeeGeneralReceipt/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'student-due-report',
    path: '/student-due-report',
    component: loadView('StudentDueReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'advance-journal',
    path: '/advance-journal',
    component: loadView('AdvanceJournal/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'create-journal',
    path: '/create-journal',
    component: loadView('CreateJournal/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'title-wise-eod-collection-report',
    path: '/title-wise-eod-collection-report',
    component: loadView('TitleWiseEodCollectionReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'eod-collection-report-detail',
    path: '/eod-collection-report-detail/:sod',
    component: loadView('EodCollectionReportDetail/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'summary-report',
    path: '/summary-report',
    component: loadView('SummaryReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'discount-penalty',
    path: '/discount-penalty',
    component: loadView('DiscountPenalty/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'billing-month',
    path: '/billing-month',
    component: loadView('BillingMonth/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'title-wise-due-report',
    path: '/title-wise-due-report',
    component: loadView('TitleWiseDueReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'title-wise-due-report-details',
    path: '/title-wise-due-report-details',
    component: loadView('TitleWiseDueReport/details'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'customized-fee-heads-report',
    path: '/customized-fee-heads-report',
    component: loadView('CustomizedFeeReport/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    path: '/billing-invoice-correction',
    component: loadView('InvoiceCorrection/index'),
    meta: {
      auth: true,
      permission: 'billing-report-statement',
    },
  },
  {
    name: 'security-deposit-report',
    path: '/security-deposit-report',
    component: loadView('SecurityDepositReport/index'),
    meta: {
      auth: true,
      permission: 'billing-report-statement',
    },
  },
  {
    name: 'billing-eca-head',
    path: '/billing-eca-head',
    component: loadView('EcaHeads/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
  {
    name: 'individual-heads',
    path: '/individual-eca-head',
    component: loadView('IndividualEcaHeads/index'),
    meta: {
      auth: true,
      permission: true,
    },
  },
];

export default routes;
