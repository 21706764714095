import Rest from '@/library/Rest'

export const unlockProtection = ({commit, state}, payload) => {
    return new Promise((resolve, reject) => {
        Rest().post('').then(({data}) => {
            state.items = data.data;
            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            state.fetching = false;
        });
    });
};