/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/schoolGuardianAssign/_components/${view}.vue`)
}

let routes = [
    {
        path: 'school-guardian-assign',
        name: 'school-guardian-assign',
        component: loadView('index'),
        meta: {
            auth: true
        }
    }
];

export default routes;