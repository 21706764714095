<template>
  <router-link style="color: white" :to="'/m-student-result'">
    <div class="statement-content">
      <div>
        <img
          src="https://img.icons8.com/external-prettycons-flat-prettycons/120/000000/external-exam-results-education-prettycons-flat-prettycons.png"
          alt=""
        />
        <h1>{{title}}</h1>
        <h6>{{info}}</h6>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
