import Rest from '@/library/Rest'
import storage from '@/library/Storage'

export const fetchChildren = ({commit, state}, payload) => {
    return new Promise((resolve, reject) => {
        let endpoint = '/api/guardian-child?rowsPerPage=25';

        const selectedChild = storage.get('_s_ch');
        if (selectedChild) {
            state.selectedChild = JSON.parse(selectedChild);
        }

        Rest().get(endpoint).then(({data}) => {
            state.children = data.data;

            if (!selectedChild && data.data.length) {
                const child = data.data[0];
                const childData = {};
                if (Object.keys(child.academic).length > 0) {
                    childData.value = child.id;
                    childData.text = child.full_name;
                    childData.grade_id = child.academic.grade_id;
                    childData.section_id = child.academic.section_id;
                    childData.batch_id = child.academic.batch_id;
                    childData.class_teacher_name = child.academic.class_teacher ? child.academic.class_teacher.name : '';
                    childData.class_teacher_id = child.academic.class_teacher ? child.academic.class_teacher.id : '';
                    childData.class_teacher_mobile = child.academic.class_teacher ? child.academic.class_teacher.mobile : '';
                    childData.enroll_code = child.academic ? child.academic.enroll_code : '';
                    childData.enroll_id = child.academic ? child.academic.enroll_id : '';
                }

                state.selectedChild = childData;
                storage.set('_s_ch', JSON.stringify(childData));
            }

            resolve(data)
        }).catch(err => {
            reject(err);
        }).finally(() => {
            // state.grade.fetching = false;
        });
    });
};