/** Lazy Load View **/
function loadView(view) {
    return () =>
        import ( /* webpackChunkName: "view-[request]" */ `@/modules/transit/_components/${view}.vue`)
}

let routes = [{
        name: 'services',
        path: '/services',
        component: loadView('Services/index'),
        meta: {
            auth: true,
            permission: 'services-read'
        }
    }, {
        name: 'modules',
        path: '/modules',
        component: loadView('Modules/index'),
        meta: {
            auth: true,
            permission: 'modules-read'
        }
    }, {
        name: 'school-payments',
        path: '/school-payments',
        component: loadView('School/AllPayments'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    }, {
        name: 'module-permission',
        path: '/module/:moduleId/permissions',
        component: loadView('Modules/mpermission'),
        meta: {
            auth: true,
            permission: 'modules-read'
        }
    },
    {
        name: 'permissions',
        path: '/permissions',
        component: loadView('Permission/index'),
        meta: {
            auth: true,
            permission: 'modules-read'
        }
    },
    {
        name: 'roles',
        path: '/roles',
        component: loadView('Roles/index'),
        meta: {
            auth: true,
            permission: 'roles-read'
        }
    }, {
        name: 'add-role',
        path: '/roles/add',
        component: loadView('Roles/form'),
        meta: {
            auth: true,
            permission: 'roles-create'
        }
    }, {
        name: 'edit-role',
        path: '/roles/:roleSlug/update',
        component: loadView('Roles/form'),
        meta: {
            auth: true,
            permission: 'roles-update'
        }
    },
    {
        name: 'guardian-profile',
        path: '/guardian/:id',
        component: loadView('Members/Guardian/profile'),
        meta: {
            auth: true,
            permission: 'guardian-read'
        }
    },
    {
        name: 'schools',
        path: '/schools',
        component: loadView('School/index'),
        meta: {
            auth: true,
            permission: 'school-read'
        }
    }, {
        name: 'payment-alert',
        path: '/payment-alert',
        component: loadView('PaymentAlert/index'),
        meta: {
            auth: true,
            permission: 'school-read'
        }
    },
    {
        name: 'schools-form',
        path: '/schools/form',
        component: loadView('School/form'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },
    {
        name: 'schools-edit',
        path: '/schools/form/:id',
        component: loadView('School/form'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },
    {
        name: 'school-data-entry',
        path: '/school/data-entry/:id',
        component: loadView('School/dataEntry'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },
    {
        name: 'staffs',
        path: '/staffs',
        component: loadView('Members/Staffs/index'),
        meta: {
            auth: true,
            permission: 'staff-read'
        }
    }, {
        name: 'staff-profile',
        path: '/staff/:id',
        component: loadView('Members/Staffs/profile'),
        meta: {
            auth: true,
            permission: 'staff-read'
        }
    },
    {
        name: 'guardians',
        path: '/guardians',
        component: loadView('Members/Guardian/index'),
        meta: {
            auth: true,
            permission: 'guardian-read'
        }
    },
    {
        name: 'Attendance',
        path: '/staff-attendance',
        component: loadView('Members/Staffs/index'),
        meta: {
            auth: true,
            permission: 'guardian-read'
        }
    },
    {
        name: 'settings',
        path: '/settings',
        component: loadView('Settings/index'),
        meta: {
            auth: true,
            permission: 'staff-update'
        }
    }, {
        name: 'my-account',
        path: '/my-account',
        component: loadView('Profile/index'),
        meta: {
            auth: true,
            // permission: 'staff-update'
        }
    }, {
        name: 'school-setting',
        path: '/school-setting/:id',
        component: loadView('SchoolSetting/index'),
        meta: {
            auth: true,
            // permission: 'staff-update'
        }
    },
    {
        name: 'student-birthday-list',
        path: 'student-birthday-list',
        component: loadView('StudentBirthdayList/index'),
        meta: {
            auth: true,
            // permission: 'staff-update'
        }
    },
    {
        name: 'birthday-list',
        path: 'birthday-list',
        component: loadView('BirthdayList/index'),
        meta: {
            auth: true,
            // permission: 'staff-update'
        }
    },
    {
        name: 'student-no-guardian-assign',
        path: 'student-no-guardian-assign',
        component: loadView('Notifications/noguardian'),
        meta: {
            auth: true,
            // permission: 'staff-update'
        }
    },
    {
        name: 'school-billing-monitor',
        path: '/school/billing-monitor/:id',
        component: loadView('School/billingMonitor'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },
    {
        name: 'authentication-enabler',
        path: '/authentication-enabler',
        component: loadView('AuthenticationEnabler/index'),
        meta: {
            auth: true,
            permission: 'staff-create'
        }
    },
    {
        name: 'deleted-users',
        path: '/deleted-users',
        component: loadView('DeletedUsers/index'),
        meta: {
            auth: true,
            permission: 'school-create'
        }
    },
    {
        name: 'referal-school',
        path: '/referal-school',
        component: loadView('SchoolReferal/index'),
        meta: {
            auth: true,
            permission: 'school-read'
        }
    },
    {
        name: 'school-services',
        path: '/school-services',
        component: loadView('SchoolServices/index'),
        meta: {
            auth: true,
            permission: 'school-read'
        }
    },
    {
        name: 'content-management',
        path: '/content-management',
        component: loadView('ContentManager/index'),
        meta: {
            auth: true,
            permission: 'school-read'
        }
    },
    {
        name: 'admin-info-detail',
        path: '/admin-info-detail/:id',
        component: loadView('ContentManager/detail'),
        meta: {
            auth: true,
            permission: true
        }
    },
    {
        name: 'video-detail',
        path: '/video-detail',
        component: loadView('ContentManager/video'),
        meta: {
            auth: true,
            permission: true
        }
    },
    {
        name: 'example-file',
        path: '/example-file',
        component: loadView('ContentManager/example'),
        meta: {
            auth: true,
            permission: true
        }
    },
    {
        name: 'staffs-attendances',
        path: '/staffs-attendances',
        component: loadView('Members/StaffAttendance/index'),
        meta: {
            auth: true,
            permission: 'staff-read'
        }
    }, 
];

export default routes;