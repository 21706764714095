/** Lazy Load View **/
import NavFactory from "../../../library/NavFactory";

function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/library/_components/${view}.vue`)
}

let routes = [
    {
        name: 'authors',
        path: '/authors',
        component: loadView('Authors/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'donors',
        path: '/donors',
        component: loadView('Donor/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'publishers',
        path: '/publishers',
        component: loadView('Publisher/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'racks',
        path: '/racks',
        component: loadView('Rack/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'vendors',
        path: '/vendors',
        component: loadView('Vendors/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'categories',
        path: '/categories',
        component: loadView('Category/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'sectors',
        path: '/sectors',
        component: loadView('Sector/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'books',
        path: '/books',
        component: loadView('Books/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'books_issue',
        path: '/books_issue',
        component: loadView('Books/BookIssue'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'book_report',
        path: '/book-report',
        component: loadView('BookReport/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'book_issue_report',
        path: '/book-issue-report',
        component: loadView('BookIssueReport/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'fine_collction_report',
        path: '/fine-collection-report',
        component: loadView('FineCollectionReport/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    },
    {
        name: 'book_log',
        path: '/book-log',
        component: loadView('BookLog/index'),
        meta: {
            auth: true,
            permission: 'library-read'
        }
    }
];


export default routes;