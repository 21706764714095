/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/sms/_components/${view}.vue`)
}

let routes = [
    {
        name: 'templates',
        path: '/templates',
        component: loadView('Templates/index'),
        meta: {
            auth: true,
            permission: 'batch-create'
        }
    }, {
        name: 'single-sms',
        path: '/single-sms',
        component: loadView('SingleSMS/index'),
        meta: {
            auth: true,
            permission: 'batch-create'
        }
    }, {
        name: 'group-sms',
        path: '/group-sms',
        component: loadView('GroupSMS/index'),
        meta: {
            auth: true,
            permission: 'batch-create'
        }
    }, {
        name: 'bulk-sms',
        path: '/bulk-sms',
        component: loadView('BulkSMS/index'),
        meta: {
            auth: true,
            permission: 'batch-create'
        }
    }, {
        name: 'sms-report',
        path: '/sms-report',
        component: loadView('SMSReport/index'),
        meta: {
            auth: true,
            permission: 'batch-create'
        }
    }
];

export default routes;