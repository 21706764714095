/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/transportation/_components/${view}.vue`)
}

let routes = [
    {
        name: 'vehicles',
        path: '/vehicles',
        component: loadView('Vehicles/index'),
        meta: {
            auth: true,
            permission: 'vehicle-read'
        }
    }, {
        name: 'transportation-routes',
        path: '/transportation-routes',
        component: loadView('Routes/index'),
        meta: {
            auth: true,
            permission: 'route-read'
        }
    }, {
        name: 'route-fare',
        path: '/route-fare',
        component: loadView('Fare/index'),
        meta: {
            auth: true,
            permission: 'fare-read'
        }
    }, {
        name: 'transport-report',
        path: '/transport-report',
        component: loadView('Report/index'),
        meta: {
            auth: true,
            permission: 'fare-read'
        }
    }, {
        name: 'manage-transport',
        path: '/manage-transport',
        component: loadView('Manage/index'),
        meta: {
            auth: true,
            permission: 'fare-read'
        }
    }, {
        name: 'migrate-transport',
        path: '/migrate-student-transport',
        component: loadView('Migrate/index'),
        meta: {
            auth: true,
            permission: 'fare-read'
        }
    }
];

export default routes;