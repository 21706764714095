let ls = window.localStorage;
import cookie from 'js-cookie';

let storage = {
    set: (name, value) => {
        ls.setItem(name, value)
    },
    get: (name) => {
        return ls.getItem(name)
    },
    delete: (name) => {
        ls.removeItem(name)
    },
    clear: () => {
        ls.clear()
    },
    cookieSet: (name, value) => {
        return cookie.set(name, value, { expires: 365 });
    },
    cookieGet: (name) => {
        return cookie.get(name);
    },
    sessionSet: (name, value) => {
        return window.sessionStorage.setItem(name, value);
    },

    sessionGet: (name) => {
        return window.sessionStorage.getItem(name);
    },
    sessionRemove: (name) => {
        return window.sessionStorage.removeItem(name);
    }
};

export default storage
