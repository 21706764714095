<template>
  <div>
    <v-text-field
      id="imagePickerText"
      v-if="uiType === 'text'"
      :disabled="disabled"
      outlined
      dense
      :value="imagePicker.name"
      v-model="imagePicker.name"
      :label="label"
      green
      prepend-inner-icon="add_a_photo"
      @click="onPickFile"
      required
    ></v-text-field>
    <div
      v-else-if="uiType === 'dragger'"
      class="image-picker-container"
      @click="onPickFile"
      style="height: 250px"
    >
      <img
        v-if="imagePicker.image || oldImage"
        :src="imagePicker.image ? imagePicker.image : oldImage"
        style="width: 100%; height: 100%; object-fit: cover"
      />
      <label for="">Click to upload an image</label>
      <div
        v-if="imagePicker.image || oldImage"
        style="
          position: absolute;
          right: 10px;
          z-index: 2;
          top: 10px;
          height: 20px;
          width: 20px;
          background-color: red;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        @click="cropCancel"
      >
        <v-icon @click="reset()" color="white">close</v-icon>
      </div>
    </div>
    <v-flex v-else xs12 text-center class="">
      <v-scroll-y-transition>
        <v-btn outlined @click="onPickFile">
          <v-icon left dark small>add_a_photo</v-icon>
          {{ label }}
        </v-btn>
      </v-scroll-y-transition>
    </v-flex>
    <input
      id="image_picker_input"
      type="file"
      style="display: none"
      ref="image"
      accept="image/*"
      @change="onFilePicked"
    />
    <v-dialog v-model="imagePicker.dialog" persistent max-width="800">
      <v-card>
        <vue-cropper
          ref="cropper"
          :guides="true"
          :view-mode="1"
          drag-mode="crop"
          :auto-crop-area="0.3"
          :background="false"
          :min-crop-box-width="width"
          :max-crop-box-width="maxWidth"
          :min-crop-box-height="height"
          :max-crop-box-height="maxHeight"
          :aspect-ratio="cropperRatio"
          :rotatable="true"
          :src="imagePicker.src"
          :img-style="{ width: '500px', height: '500px' }"
          alt="Source Image"
        >
        </vue-cropper>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cropCancel">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="cropImage">Crop</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
.cropper-canvas {
  img {
    max-height: 400px;
  }
}
.image-picker-container {
  width: 100%;
  background: #efefef;
  border: 1px dashed #c3c3c3;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border-color: #888;
  }
  label {
    font-size: 18px;
    text-align: center;
    position: absolute;
    left: 50%;
    cursor: pointer;
    color: #999;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<script>
import Compressor from "compressorjs";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import ImageCompressor from "image-compressor.js";

const options = {
  maxSizeMB: 0.5, // (default: Number.POSITIVE_INFINITY)
  maxWidthOrHeight: 1024, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
  // useWebWorker: false,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  // maxIteration: 10        // optional, max number of iteration to compress the image (default: 10)
};

export default {
  components: { VueCropper },
  props: {
    label: {
      default: "Select Image",
    },
    disabled: {
      default: false,
    },
    uiType: {
      default: "button",
    },
    ratio: {
      default: 1,
    },
    height: {
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 300,
    },
    oldImage: {
      default: null,
    },
    value: { default: null },
    compressImage:{
      default:false,
    }
  },
  data: () => ({
    imagePicker: {
      dialog: false,
      name: null,
      image: null,
      src: null,
      cropped: {},
    },
    originalFile:null,
    maxWidth: 1024,
    maxHeight: 2024,
  }),
  computed: {
    cropperHeight() {
      if (this.ratio == null) {
        return this.height;
      } else return this.ratio === 1 ? 300 : 300;
    },
    cropperWidth() {
      if (this.ratio == null) {
        return this.width;
      } else return this.ratio === 1 ? 512 : 910.22;
    },
    cropperRatio() {
      return this.ratio === 1 ? 1 : !this.ratio ? 16 / 9 : this.ratio;
    },
  },
  watch: {
    value(val) {
      this.imagePicker.image = val;
    },
  },

  methods: {
    clearImage(e) {
      this.imagePicker = {
        dialog: false,
        name: null,
        image: null,
        src: null,
        cropped: {},
      };
    },
    /**
     * Image Upload Handler
     */
    onPickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const file = e.target.files[0];
      if (file && !file.type.includes("image/")) {
        return;
      }
      if(this.compressImage){
         const imageCompressor = new ImageCompressor();
        imageCompressor
          .compress(file, { quality: 0.1 })
          .then((result) => {
            this.originalFile = result;
          })
          .catch((err) => {
            // Handle the error
          });
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imagePicker.src = event.target.result;
          this.imagePicker.name = file.name;
          this.imagePicker.dialog = true;
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    },

    reset() {
      this.imagePicker = {
        dialog: false,
        name: "",
        image: null,
        src: null,
        cropped: {},
      };
      this.$emit("result", this.imagePicker);
      this.$refs.image.value = "";
      this.$emit('onRemove')
    },

    cropCancel(e) {
      e.stopPropagation();
      this.imagePicker.dialog = false;
      this.imagePicker = {
        dialog: false,
        name: null,
        image: null,
        src: null,
        cropped: {},
      };
      this.$emit("result", this.imagePicker);
      this.$refs.image.value = "";
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const canvas = this.$refs.cropper.getCroppedCanvas();

      this.imagePicker.image = canvas.toDataURL();

      let vm = this;
      canvas.toBlob(function (blob) {
        new Compressor(blob, {
          strict: true,
          quality: 0.3,
          maxWidth: 1024,
          maxHeight: 1024,
          success(result) {
            vm.imagePicker.cropped = result;
            vm.imagePicker.dialog = false;
            vm.$emit("result", vm.imagePicker);
            vm.$emit("input", vm.imagePicker.image);
            vm.$emit("fileOriginal", vm.originalFile);
          },
          error(error) {},
        });
      });
    },
  },
};
</script>
