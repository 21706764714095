<template>
  <div>
    <v-select
      :disabled="disabled"
      v-bind:value="value"
      :loading="grade.grade.fetching"
      :items="gradeItems"
      @change="setGrade"
      outlined
      dense
      :multiple="multiple"
      class="pa-0"
      :label="label || 'Select grade'"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    value: {},
    label: {},
    batchId: {},
    disabled: { default: false },
    multiple: { default: false },
    companyId: {},
    inside: { default: false },
    withAll: { default: false },
  },
  data() {
    return {
      gradeList: [],
      selectedGradeId: "",
    };
  },
  watch: {
    batchId(value) {
      this.fetchGrades();
    },
  },
  computed: {
    ...mapState(["grade"]),
    gradeItems() {
      let output = [];
      if (this.withAll === true) {
        output.push({ id: "all", name: "All", sections: [] });
      }
      const grades = this.inside ? this.gradeList : this.grade.grade.items;
      output = [...output, ...grades];
      return output.map((item) => {
        return { value: item.id, text: item.name, sections: item.sections };
      });
    },
    selectedGrade() {
      let output = {};
      const selectedGrade = this.inside
        ? this.selectedGradeId
        : this.grade.grade.selected;

      const grades = this.inside ? this.gradeList : this.grade.grade.items;
      grades.map((item) => {
        if (item.id === selectedGrade) {
          output = item;
        }
      });
      return output;
    },
  },
  mounted() {
    this.fetchGrades();
  },
  methods: {
    ...mapActions(["getGrades"]),
    ...mapMutations(["SET_SECTIONS"]),
    setGrade(value) {
      if (this.inside) {
        this.selectedGradeId = value;
      } else {
        this.grade.grade.selected = value;
      }

      this.$emit("input", value);
      this.$emit("selectedGrade", this.selectedGrade);
    },
    fetchGrades() {
      const filter = {};
      filter.filter = this.filter;
      if (this.batchId) filter.batchId = parseInt(this.batchId);
      if (this.companyId) filter.companyId = parseInt(this.companyId);
      filter.inside = this.inside;
      this.getGrades(filter).then((data) => {
        this.gradeList = [...data.data];
      });
    },
  },
};
</script>
<style lang="scss"></style>
