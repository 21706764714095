export const SET_DATE = (state, payload = []) => {
    // console.log(state);
    // state.section.items = payload;
};


export const SET_COUNT = (state, payload = []) => {
   
};

