<template>
  <div class="guardian-head">
    <div class="container">
      <div class="guardian-content">
        <div class="icon-box">
          <div class="row">
            <div class="col-6">
              <div class="guardian-icon">
                <!-- <div class="img-circle"> -->
                <div class="left-back-arrow">
                  <router-link
                    style="font-size:20px;color:white;padding-right:20px;"
                    :to="back"
                    ><i class="fas fa-arrow-left"></i
                  ></router-link>
                </div>
                <!-- </div> -->
                <div class="guardian-info">
                  <h1>{{ title }}</h1>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="guardian-icon-pt-2">
                <a href="#"><i class="fas fa-question-circle"></i></a>
                <a @click="$emit('onFilterClick')" v-if="filterItems.length < 1"
                  ><i class="fas fa-ellipsis-v"></i
                ></a>
                <v-menu bottom left v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" href="#"
                      ><i class="fas fa-ellipsis-v"></i
                    ></a>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, i) in filterItems" :key="i">
                      <v-list-item-title @click="$emit('onFilterOptionClick',{item, i})">
                        <span
                          style="cursor: pointer"
                          >{{ item.title }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    back: {
      required: false,
      default: "/guardian",
    },
    filterItems: {
      required: false,
      default: ()=>[],
    }
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
