<template>
  <v-autocomplete
    hide-no-data
    :disabled="disabled === true"
    :search-input.sync="search"
    :items="student_list"
    clearable
    :loading="loading"
    @click:clear="$emit('onClear')"
    @change="onChange"
    class="pa-0"
    label="Start typing to search students (Enroll/Name)"
    item-text="name"
    item-value="enroll_id"
    v-model="enroll"
    outlined
    dense
  >
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <img :src="data.item.avatar || logo" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.sub_title"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import cookie from "js-cookie";

let typingTimer;
let doneTypingInterval = 400;

export default {
  // props: ["companyId", "disabled", "withDeleted","calculateProfilePercentage"],
  props:{
    companyId:{
      type:Boolean,
    },
    disabled:{
      type:Boolean,
    },
    withDeleted:{
      type:Boolean,
    },
    calculateProfilePercentage:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      enroll: "",
      loading: false,
      student_list: [],
      search: "",
      // batchLoading: false,
      // batches: [],
    };
  },
  watch: {
    search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.searchStudentApi(val);
      }, doneTypingInterval);
    },
  },
  computed: {
    logo() {
      return cookie.get("_cl") || "https://image.ibb.co/cVTEsG/eAcademy.png";
    },
  },
  mounted() {
    if (this.companyId && this.$auth.getRole() !== "super") {
      throw Error("Invalid user of search student");
    }
  },
  methods: {
    searchStudentApi(val) {
      if (!val) return;
      this.isLoading = true;
      let endpoint =
        "api/search-student?rowsPerPage=10&page=1&sortBy=name&rowsPerPage=10&slim=true&search=" +
        val.trim();
      if (this.$auth.getRole() === "super" && this.companyId) {
        endpoint += "&super_mode_company_id=" + this.companyId;
      }
      if (this.withDeleted === true) {
        endpoint += "&with_deleted=true";
      }
      if(this.calculateProfilePercentage === true){
        endpoint += "&withProfileProgress=true";
      }
      this.$rest
        .get(endpoint)
        .then(({ data }) => {
          this.student_list = data.data.map(function(item) {
            let subTitle =
              item.enroll_code +
              ", " +
              item.grade +
              ", " +
              item.section +
              ", " +
              item.roll;
            if (item.guardian && item.guardian.name) {
              subTitle += " (" + item.guardian.name;
              if (item.guardian.mobile) {
                subTitle += " / " + item.guardian.mobile;
              }
              subTitle += ")";
            }
            return {
              enroll_code: item.enroll_code,
              enroll_id: item.enroll_id,
              name: item.name,
              avatar: item.profile_image,
              details: item,
              sub_title: subTitle,
              address: item.address,
              guardian: item.guardian,
              id: item.id,
            };
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange(enroll) {
      let student = null;
      this.student_list.map((item) => {
        if (item.enroll_id === parseInt(enroll)) {
          student = item;
        }
      });
      this.$emit("onSelectStudent", student);
    },
  },
};
</script>
<style lang="scss">
.v-text-field__details {
  display: none;
}
</style>
