// export default class SMS {
//     constructor() {
//         this.parts = 1;
//         this.part = 1;
//         this.chars_sms = 160;
//         this.chars_used = 0;
//         this.message_characters = 0;
//         this.message_parts = 0;
//         this.msg_type = 1;
//         this.charset7bit = ['@', 'Â£', '$', 'Â¥', 'Ã¨', 'Ã©', 'Ã¹', 'Ã¬', 'Ã²', 'Ã‡', "\n", 'Ã˜', 'Ã¸', "\r", 'Ã…', 'Ã¥', 'Î”', '_', 'Î¦', 'Î“', 'Î›', 'Î©', 'Î ', 'Î¨', 'Î£', 'Î˜', 'Îž', 'Ã†', 'Ã¦', 'ÃŸ', 'Ã‰', ' ', '!', '"', '#', 'Â¤', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 'Â¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ã„', 'Ã–', 'Ã‘', 'Ãœ', 'Â§', 'Â¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Ã¤', 'Ã¶', 'Ã±', 'Ã¼', 'Ã '];
//         this.charset7bitext = ["\f", '^', '{', '}', '\\', '[', '~', ']', '|', 'â‚¬'];
//     }

//     static isUnicode(value) {
//         let code = /[^\u0000-\u00ff]/.test(value);
//         if (code) return 2;
//         else return 1;
//     }

//     calculate(message) {

//         this.chars_used = 0;
//         this.parts = 1;

//         let code = /[^\u0000-\u00ff]/.test(message);
//         if (code) this.msg_type = 2;
//         else this.msg_type = 1;


//         let content = message.replace(/\n/g, '^');
//         if (content === undefined) return;
//         let chars_arr = content.split("");
//         let coding = '7bit';

//         chars_arr.forEach(item => {
//             if (this.charset7bit.indexOf(item) >= 0) {
//                 this.chars_used = this.chars_used + 1;
//             } else if (this.charset7bitext.indexOf(item) >= 0) {
//                 this.chars_used = this.chars_used + 2;
//             } else {
//                 coding = '16bit';
//                 this.chars_used = chars_arr.length;
//                 return true // break
//             }
//         });

//         if (coding === '7bit') {
//             if (this.chars_used > 160) {
//                 this.parts = Math.ceil(this.chars_used / 153);
//                 let part_chars_used = 7;
//                 this.chars_sms = 153;

//                 chars_arr.forEach((item, i) => {
//                     if (part_chars_used + 1 > 160) {
//                         this.part++;
//                         part_chars_used = 7;
//                     }
//                     if (this.charset7bitext.indexOf(item) >= 0 && part_chars_used + 2 > 160) {
//                         this.part++;
//                         part_chars_used = 7;
//                     } else if (this.charset7bitext.indexOf(chars_arr[i]) === -1) {
//                         part_chars_used++;
//                     } else {
//                         part_chars_used += 2;
//                     }
//                 })
//             } else {
//                 this.chars_sms = 160;
//             }
//         } else {
//             if (this.chars_used > 70) {
//                 this.parts = Math.ceil(this.chars_used / 67);
//                 let part_chars_used = 3;
//                 this.chars_sms = 67;
//                 chars_arr.forEach(() => {
//                     if (part_chars_used + 1 > 70) {
//                         this.part++;
//                         part_chars_used = 3;
//                     }
//                     part_chars_used++
//                 })
//             } else {
//                 this.chars_sms = 70;
//             }
//         }
//         return {
//             'characters': this.chars_used,
//             'total_sms': this.parts,
//             'msg_type': this.msg_type
//         }
//     }
// }



export default class SMSCalculator {
    constructor() {
        this.encoding = {
            // UTF16: [70, 64, 67],
            UTF16: [70, 70, 70],
            GSM_7BIT: [160, 160, 160],
            // GSM_7BIT: [160, 146, 153],
            GSM_7BIT_EX: [160, 160, 160],
            // GSM_7BIT_EX: [160, 146, 153],
        };
        this.charset = {
            // gsmEscaped: '\\^{}\\\\\\[~\\]|€',
            gsmEscaped: '\\^{}\\\\\\[~\\]|€',
            gsm: '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà',
        };
        this.msg_type = 1;
    }
    // Encoding

    // Charset
    // ,

    // Regular Expression
    regex() {
        return {
            gsm: RegExp(`^[${this.charset.gsm}]*$`),
            gsmEscaped: RegExp(`^[\\${this.charset.gsmEscaped}]*$`),
            gsmFull: RegExp(`^[${this.charset.gsm}${this.charset.gsmEscaped}]*$`),
        };
    }

    // Method
    detectEncoding(text) {
        if (text.match(this.regex().gsm)) {
            return this.encoding.GSM_7BIT;
        } else if (text.match(this.regex().gsmFull)) {
            return this.encoding.GSM_7BIT_EX;
        } else {
            return this.encoding.UTF16;
        }
    }
    getEscapedCharCount(text) {
        return [...text].reduce((acc, char) => acc + (char.match(this.regex().gsmEscaped) ? 0 : 0), 0);
        // return [...text].reduce((acc, char) => acc + (char.match(this.regex().gsmEscaped) ? 1 : 0), 0);
    }

    getPartData(totalLength, encoding) {
        let maxCharCount = encoding[2];
        let numberOfSMS = Math.ceil(totalLength / maxCharCount);
        let remaining = maxCharCount - (totalLength - (encoding[0] + encoding[1] + (encoding[2] * (numberOfSMS - 3))));

        if (totalLength <= encoding[0]) {
            maxCharCount = encoding[0];
            numberOfSMS = 1;
            remaining = maxCharCount - totalLength;
        } else if (totalLength > encoding[0] && totalLength <= (encoding[0] + encoding[1])) {
            maxCharCount = encoding[1];
            numberOfSMS = 2;
            remaining = maxCharCount - (totalLength - encoding[0]);
        }

        return {
            maxCharCount,
            numberOfSMS,
            remaining,
            totalLength,
            msg_type: this.msg_type
        };
    }
    getCount(text) {
        let length = text.length;
        const encoding = this.detectEncoding(text);

        if (encoding === this.encoding.GSM_7BIT_EX) {
            length += this.getEscapedCharCount(text);
        }

        let code = /[^\u0000-\u00ff]/.test(text);
        if (code) this.msg_type = 2;
        else this.msg_type = 1;


        return this.getPartData(length, encoding);
    }
};

let value = '';
let calculator = new SMSCalculator();
const count = calculator.getCount(value);

    // document.getElementById('sms-count').innerText = `${count.remaining} / ${count.numberOfSMS}`;

