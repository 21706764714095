<template>
  <div>
    <v-autocomplete
      :disabled="disabled === true"
      :search-input.sync="search"
      :items="user_list"
      clearable
      :loading="loading"
      @change="onChange"
      class="pa-0"
      :label="label"
      item-text="name"
      item-value="id"
      v-model="enroll"
      outlined
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          small
          label
          v-bind="data.attrs"
          :input-value="data.selected"
          :close="displayClose"
          @click="data.select"
          @click:close="remove(data.item)"
        >
          <v-avatar left>
            <v-img :src="data.item.avatar"></v-img>
          </v-avatar>
          &nbsp;&nbsp;
          <span style="padding-left:5px;">{{ data.item.name }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <img :src="data.item.avatar || logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.sub_title"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import cookie from "js-cookie";

let typingTimer;
let doneTypingInterval = 400;

export default {
  props: {
    companyId: {
      required: false,
    },
    multiple: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
    },
    withDeleted: {
      required: false,
    },
    label: {
      required: false,
      default: "Start typing company name",
    },
    role: {
      required: false,
    },
    displayClose:{
      default:true,
    }
  },
  data() {
    return {
      enroll: "",
      loading: false,
      user_list: [],
      search: "",
    };
  },
  watch: {
    search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.searchCompanyApi(val);
      }, doneTypingInterval);
    },
  },
  computed: {
    logo() {
      return cookie.get("_cl") || "https://image.ibb.co/cVTEsG/eAcademy.png";
    },
  },
  mounted() {},
  methods: {
    remove(item) {
      // let deleteIndex;
      // this.user_list.map((user, index) => {
      //   if (item.id === user.id) {
      //     deleteIndex = index;
      //   }
      // });
      // if (deleteIndex >= 0) this.user_list.splice(deleteIndex, 1);
      // if (this.multiple === true) {
      //   const index = this.enroll.indexOf(item.id);
      //   if (index >= 0) this.enroll.splice(index, 1);
      // } else {
      //   this.enroll = "";
      // }
      // this.onChange(this.enroll);
    },
    searchCompanyApi(val) {
      if (!val) return;
      this.isLoading = true;
      let extraParams = "flag=" + this.type + "&search=" + val.trim();

      let endpoint =
        "api/company?rowsPerPage=10&page=1&sortBy=name&search=" + extraParams;

      if (this.withDeleted === true) {
        endpoint += "&with_deleted=true";
      }
      this.$rest
        .get(endpoint)
        .then(({ data }) => {
          data.data.map((item) => {
            let subtitle = "";
            if (item.contact.primary_email) {
              subtitle += item.contact.primary_email;
            }
            if (item.contact.primary_phone) {
              subtitle += " / " + item.contact.primary_phone;
            }
            if (item.contact.secondary_phone) {
              subtitle += " / " + item.contact.secondary_phone;
            }

            this.user_list.push({
              name: item.name,
              email: item.contact.primary_email,
              primary_mobile: item.contact.primary_phone,
              id: item.id,
              avatar: item.logo,
              sub_title: subtitle,
            });
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange(id) {
      this.$emit("input", id);
      this.$emit("onSelectCompany", id);
    },
  },
};
</script>
<style lang="scss">
.v-text-field__details {
  display: none;
}
</style>
