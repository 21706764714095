<template>
  <v-app id="app">
    <transition name="router-anim">
      <router-view class="view" />
    </transition>
    <iframe
      style="margin-left: 300px;"
      id="recieve"
      :src="getDomain"
      width="0"
      height="0"
    >
      <p>Your browser does not support iframes.</p>
    </iframe>

    <iframe
      style="margin-left: 300px;"
      id="reporting"
      :src="getReportDomain + '/web'"
      width="0"
      height="0"
    >
      <p>Your browser does not support iFrames.</p>
    </iframe>
  </v-app>
</template>

<script>
import { getServiceDomain } from "./library/helpers";

export default {
  data: () => ({
    logoutTimeInMinutes: '',
  }),
  computed: {
    getDomain() {
      return this.getServiceDomain();
    },
    getReportDomain() {
      return this.getServiceDomain("reporting");
    },
  },
  mounted() {

    if(this.$auth.authenticated()) {

      this.getAuthenticationSetting()
    }
  },

  methods: {
    getServiceDomain,
    autoLogout(){
      let initialLogoutTime =  localStorage.getItem('logoutTime');
      if(initialLogoutTime){
        const now = new Date();
        let [hours, minutes, seconds] = initialLogoutTime.split(":").map(Number);
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        date.setMinutes(date.getMinutes() + this.logoutTimeInMinutes);

        let newHours = String(date.getHours()).padStart(2, '0');
        let newMinutes = String(date.getMinutes()).padStart(2, '0');

        let newTimeString = `${newHours}:${newMinutes}`;
        
        const currentTimeString = now.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
        if (currentTimeString === newTimeString) {
          this.$storage.delete("_token");
          this.$storage.delete("_permissions");
          this.$storage.delete("_user");
          this.$storage.delete("_role");
          this.$storage.delete("_currentFiscalYear");
          this.$router.replace({ name: "login" });
          this.$storage.sessionRemove("_batch_se");
        }
      }
    },

    getAuthenticationSetting() {
      this.$rest.get("/api/authentication-setting").then(({ data }) => {
        if (data.transit && data.transit.authentication) {
          if(data.transit.authentication.logout_time && data.transit.authentication.logout_time !== 'Never'){
            this.logoutTimeInMinutes = parseInt(data.transit.authentication.logout_time.replace("min", ''));
            setInterval(() => {
              this.autoLogout()
            }, 60000);
          }
        }
      });
    },

  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Dancing+Script:400,700&&subset=vietnamese");

.badge {
  line-height: 1.5;
}

// .v-text-field.v-text-field--enclosed {
//   margin-bottom: 20px;
// }

.statement-head .v-btn__content {
  border-bottom: none !important;
}

.company-slogan {
  font-family: "Dancing Script", cursive;
  font-weight: 700;
  color: #777 !important;
}

.v-application .error {
  color: white;
}

/*td{*/
.v-alert.v-sheet.theme--light.error {
  margin-top: 10px;
  color: white;
  .v-alert__icon.v-icon {
    color: white;
  }
}

/*}*/

.divider {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  background: transparent;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  position: relative;
  top: -0.06em;
  vertical-align: middle;

  &:after {
    top: 50%;
    width: 95%;
    position: relative;
    display: table-cell;
    border-top: 2px solid #e3e3e3;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    content: "";
  }

  span {
    display: inline-block;
    padding: 0 10px 0 0;
    color: #c3c3c3;

    i {
      color: #ccc !important;
      font-size: 17px;
      display: inline-block;
      padding-right: 5px;
    }
  }
}

.title-bar-breadcrumb {
  background: white;
  padding: 15px 20px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  ul {
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      a {
        text-decoration: none;
        color: #777;
        font-weight: 500;
        i {
          line-height: 0.8;
        }
        .v-icon {
          color: #777;
        }
        i.page-icon {
          font-size: 20px;
          line-height: 1;
        }
        &.active {
          color: #333;
          i {
            color: #333;
          }
        }
      }
    }
  }
}

.role-selecter {
  .v-card__text {
    padding: 0 !important;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app {
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
  min-height: 100%;
  min-width: 100%;
}

.router-anim-enter-active {
  animation: coming 0.3s;
  animation-delay: 0.1s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 0.3s;
}

@keyframes coming {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes going {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.theme--light.v-pagination .v-pagination__item--active {
  outline: none;
}

.theme--light.v-pagination .v-pagination__navigation {
  outline: none;
}

.data-represent {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
  div {
    span {
      display: inline-block;
      border-radius: 2px;
      height: 15px;
      width: 15px;
      &.d-success {
        background-color: #4caf50 !important;
      }
      &.d-error {
        background-color: #ff5252 !important;
      }
      &.d-warning {
        background-color: #fb8c00 !important;
      }
      &.d-info {
        background-color: #999999 !important;
      }
      &.d-primary {
        background-color: #1976d2 !important;
      }
      &.d-public-holiday {
        border-left: 3px solid #ff5252;
        /*background-color: indianred !important;*/
        background-color: #ccc !important;
      }
    }
    margin-right: 40px;
  }
}

.page-container-card {
  min-height: 250px;
}

/* .page-layout {
        .title {
            color: #666;
            font-weight: bold;
        }
    } */

.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  border: 1px solid rgba(0, 0, 0, 0.54) !important;
}

/* .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none !important;
        resize: none;
    } */

.overlay {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .inner-overlay {
    background-color: white;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    opacity: 0.7;
    display: block;
    padding: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s;
    &.dont-show {
      display: none;
    }
    p {
      margin-top: 22%;
      font-size: 25px;
      color: red;
      font-weight: bold;
      font-family: "arial";
      opacity: 1;
      text-align: center;
    }
  }
}

.children-box {
  min-height: 235px;
  /*background-color: red;*/
  display: flex;
  ul {
    display: flex;
    flex: 1;
    flex-direction: column;
    li {
      flex: 1;
      .representation {
        height: 42px !important;
        width: 42px !important;
        img {
          border-radius: 50%;
        }
        /*overflow: hidden;*/
      }
      .member-label {
        .class_teacher_info {
          background-color: #535166;
          padding: 5px 15px 5px 5px;
          border-radius: 20px;
          display: flex;
          .profile-image {
            img {
              width: 100%;
            }
            margin-right: 5px;
            overflow: hidden;
            height: 30px;
            width: 30px;
            border-radius: 50%;
          }
          h5 {
            line-height: 1;
            width: 100%;
            color: white;
            + p {
              color: #999;
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .stats {
    flex: 1 !important;
  }
}

.member-stat-bar {
  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      // padding-top: 10px;
      padding-top: 5px;
      padding-right: 5px;
      padding-left: 5px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        /*background: #e8e8e8;*/
        cursor: pointer;
      }

      .representation {
        width: 35px;
        height: 35px;
        background: #eee;
        border-radius: 50%;
        padding: 6px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      .counter {
        flex: 1;
        /*padding-right: 20px;*/
        justify-content: flex-end;

        h2 {
          float: right;
          font-size: 24px !important;
        }
      }

      .member-label {
        flex: 1.9;

        h3 {
          color: #3f3d56;
          line-height: 1;
          font-size: 14px !important;
          + p {
            color: #999;
            font-size: 11px;
            // margin-top: 5px;
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }

        padding-right: 10px;
      }

      .stats {
        flex: 2;

        p {
          text-align: right;
          margin-bottom: 0;
          font-size: 16px;
          font-weight: bold;
          color: #444 !important;
        }

        width: 100%;

        .primary {
          background-color: #444 !important;
          border-color: #444 !important;
        }
      }
    }
  }
}

.nav-child-list:hover {
  background-color: #f5f5f5;
}

.v-tab.v-tab--active {
  color: #1976d2 !important;
}

/** By Yubaraj */
.primary--border {
  border-color: rgba(21, 101, 192, 1) !important;
}

a {
  text-decoration: none;
}

.ndp-selected {
  a {
    display: inline-block;
    width: 100%;
  }
}
</style>
