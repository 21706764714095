<template>
  <section
    :class="{
      'transaction-month': true,
      active: uniqueValue === this.showFooter,
    }"
    @click="expand(uniqueValue)"
  >
    <div class="transaction" @click="$emit('click')">
      <div class="transaction-content">
        <div
          :class="{
            'payment-report': true,
            error: color_code_status == 0 ? true : false,
            warning: color_code_status == 3 ? true : false,
            success: color_code_status == 1 ? true : false,
          }"
        ></div>

        <div class="transaction-details">
          <h1>{{ title }}</h1>
          <h3>{{ info }}</h3>
        </div>
      </div>

      <div class="transaction-cash">
        <h3>{{ cash }}</h3>
      </div>
    </div>
    <div v-if="showFooter!=null">
      <div class="expand-section" id="slidedown">
        <table style="width:100%;padding-left:120px;">
          <tr class="expand-head" style="font-size:26px;">
            <th>Head</th>
            <th>Amt.</th>
            <th>Paid</th>
            <th>Due</th>
          </tr>
          <br />
          <tr class="expand-fee" style="font-size:20px" v-for="(value,index) in details" :key="index">
            <td>{{value.fee_head}}</td>
            <td>{{value.fee_head_amount.numberFormat()}}</td>
            <td>{{value.paid_amount.numberFormat()}}</td>
            <td>{{value.due_amount.numberFormat()}}</td>
          </tr>
          <tr class="expand-fee" style="font-size:20px" v-if="penalty !== null">
            <td>Penalty</td>
            <td>{{penalty.amount.numberFormat()}}</td>
            
            <td v-if="color_code_status == 0 || color_code_status == 3">0.00</td>
            <td v-else>{{penalty.amount.numberFormat()}}</td>

            <td v-if="color_code_status == 0 || color_code_status == 3">{{penalty.amount.numberFormat()}}</td>
            <td v-else>0.00</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    color_code_status: {
      required: true,
    },
    title: {
      default:'',
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
    cash: {
      required: true,
      type: String,
    },

    uniqueValue: {
      required: true,
    },
    details:{
      type: Array,
      default: () => [],
    },
    penalty:{
      default:null
    }
  },
  data: () => ({
    showFooter: null,
  }),
  watch: {},
  mounted() {},
  methods: {
    expand(uniqueValue) {
      if (this.showFooter === null) {
        this.showFooter = uniqueValue;
      } else {
        this.showFooter = null;
      }
    },
    expandDiv() {
    },
  },
};
</script>
