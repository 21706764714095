/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/exam/_components/${view}.vue`)
}

let routes = [
    {
        name: 'exam',
        path: '/exam',
        component: loadView('exam/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    },
    {
        name: 'advance-practical-mode',
        path: '/advance-practical-mode',
        component: loadView('AdvancePracticeMode/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    }, {
        name: 'subject-marks',
        path: '/subject-marks',
        component: loadView('subject-marks/index'),
        meta: {
            auth: true,
            permission: 'subject-mark-read'
        }
    }, {
        name: 'exam-detail',
        path: '/exam/:examId',
        component: loadView('exam-detail/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    }, {
        name: 'exam-routine',
        path: 'exam-routine',
        component: loadView('routine/index'),
        meta: {
            auth: true,
            permission: 'routine-read'
        }
    },{
        name: 'certificate-template',
        path: '/certificate-template',
        component: loadView('CertificateTemplate/index'),
        meta: {
            auth: true,
            permission: 'marks-generate-bulk'
        }
    }, {
        name: 'marksheet',
        path: '/marksheet',
        component: loadView('marksheet/index'),
        meta: {
            auth: true,
            permission: 'marks-generate-bulk|marks-generate-single|marks-ledger-generate-single|marks-ledger-generate-bulk'
        }
    }, {
        name: 'marks-entry',
        path: '/marks-entry',
        component: loadView('MarksEntry/index'),
        meta: {
            auth: true,
            permission: 'marks-input-read|marks-input-create|marks-input-update|marks-input-delete'
        }
    }, {
        name: 'entrance-card',
        path: '/entrance-card',
        component: loadView('EntranceCard/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    }, {
        name: 'pa-manager',
        path: '/pa-manager',
        component: loadView('PerformanceAnalysis/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    }, {
        name: 'pa-entry',
        path: '/pa-entry',
        component: loadView('PerformanceAnalysisEntry/index'),
        meta: {
            auth: true,
            permission: 'marks-input-read|marks-input-create|marks-input-update|marks-input-delete'
        }
    }, {
        name: 'attendance-setting',
        path: '/attendance-setting',
        component: loadView('Attendance/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    }, {
        name: 'certificate',
        path: '/certificate',
        component: loadView('Certificate/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    }, {
        name: 'result-blocked-student-list',
        path: '/result-blocked-student-list',
        component: loadView('ResultBlockedStudentList/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    },{
        name: 'individual-marks-entry',
        path: '/individual-marks-entry',
        component: loadView('IndividualMarksEntry/index'),
        meta: {
            auth: true,
            permission: 'admit-card-generate-bulk|admit-card-generate-single'
        }
    },{
        name: 'eca-head',
        path: '/eca-head',
        component: loadView('ECAHead/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    },{
        name: 'eca-list',
        path: 'eca-list/:ecaHeadId',
        component: loadView('ECAList/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    },{
        name: 'eca-exam-list',
        path: 'eca-exam-list',
        component: loadView('ECAExamSetting/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    },{
        name: 'eca-exam-setting-detail',
        path: 'eca-exam-setting-detail/:gradeId/:examId',
        component: loadView('ECAExamSettingDetail/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    },{
        name: 'eca-achievement',
        path: 'eca-achievement',
        component: loadView('ECAAchievement/index'),
        meta: {
            auth: true,
            permission: 'exam-read'
        }
    }
];

export default routes;