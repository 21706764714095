var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:{
    'transaction-month': true,
    active: _vm.uniqueValue === this.showFooter,
  },on:{"click":function($event){return _vm.expand(_vm.uniqueValue)}}},[_c('div',{staticClass:"transaction",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"transaction-content"},[_c('div',{class:{
          'payment-report': true,
          error: _vm.color_code_status == 0 ? true : false,
          warning: _vm.color_code_status == 3 ? true : false,
          success: _vm.color_code_status == 1 ? true : false,
        }}),_c('div',{staticClass:"transaction-details"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('h3',[_vm._v(_vm._s(_vm.info))])])]),_c('div',{staticClass:"transaction-cash"},[_c('h3',[_vm._v(_vm._s(_vm.cash))])])]),(_vm.showFooter!=null)?_c('div',[_c('div',{staticClass:"expand-section",attrs:{"id":"slidedown"}},[_c('table',{staticStyle:{"width":"100%","padding-left":"120px"}},[_vm._m(0),_c('br'),_vm._l((_vm.details),function(value,index){return _c('tr',{key:index,staticClass:"expand-fee",staticStyle:{"font-size":"20px"}},[_c('td',[_vm._v(_vm._s(value.fee_head))]),_c('td',[_vm._v(_vm._s(value.fee_head_amount.numberFormat()))]),_c('td',[_vm._v(_vm._s(value.paid_amount.numberFormat()))]),_c('td',[_vm._v(_vm._s(value.due_amount.numberFormat()))])])}),(_vm.penalty !== null)?_c('tr',{staticClass:"expand-fee",staticStyle:{"font-size":"20px"}},[_c('td',[_vm._v("Penalty")]),_c('td',[_vm._v(_vm._s(_vm.penalty.amount.numberFormat()))]),(_vm.color_code_status == 0 || _vm.color_code_status == 3)?_c('td',[_vm._v("0.00")]):_c('td',[_vm._v(_vm._s(_vm.penalty.amount.numberFormat()))]),(_vm.color_code_status == 0 || _vm.color_code_status == 3)?_c('td',[_vm._v(_vm._s(_vm.penalty.amount.numberFormat()))]):_c('td',[_vm._v("0.00")])]):_vm._e()],2)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"expand-head",staticStyle:{"font-size":"26px"}},[_c('th',[_vm._v("Head")]),_c('th',[_vm._v("Amt.")]),_c('th',[_vm._v("Paid")]),_c('th',[_vm._v("Due")])])
}]

export { render, staticRenderFns }