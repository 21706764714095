
function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/${view}.vue`)
}

let routes = [
	{
		path: '/',
		component: loadView('layout/_components/index'),
		meta: { auth: true },
		children: []
	},
	{
		path:'/payment_success/:status?',
		component: loadView('layout/payment'),
		meta: { auth: true},
		name:'Payment Verification',
		children: []
	}
];

export default routes;