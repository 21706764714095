<template>
  <v-btn
    :disabled="disabled"
    v-if="hasPermission"
    :class="'eac-add-button search-button ' + (disabled ? 'disabled' : '')"
    @click="!disabled ? $emit('action') : ''"
    :color="color || 'primary'"
    text-color="white"
    outlined
  >
    <v-icon>{{ icon || "search" }}</v-icon>
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  props: ["icon", "permission", "color", "disabled"],
  data: () => ({
    hasPermission: false,
  }),
  mounted() {
    if (this.permission === true) {
      this.hasPermission = true;
      return true;
    }

    if (this.permission) this.hasPermission = this.$auth.can(this.permission);
  },
};
</script>
<style lang="scss">
.eac-add-button {
  margin-top: -3px;
  .v-chip__content {
    cursor: pointer;
  }
}

.primary.disabled {
  background-color: #91c5fb !important;
  border-color: #91c5fb !important;
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
  }
}

.v-chip.disabled:focus:not(.v-chip--disabled) {
  border-color: rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.v-chip .v-avatar {
  margin-right: 0 !important;
}

.search-button {
  margin-top: -25px;
  .v-icon {
    font-size: 20px;
  }
}
</style>
