var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'children-section small': true,
    is_selectable: this.result,
  }},[_c('div',{staticClass:"container"},[(_vm.children.length < 1)?_c('div',[(true)?_c('v-skeleton-loader',_vm._b({attrs:{"elevation":"0","loading":true,"type":"list-item-avatar-two-line"}},'v-skeleton-loader',_vm.attrs,false)):_vm._e(),(true)?_c('v-skeleton-loader',_vm._b({attrs:{"elevation":"0","loading":true,"type":"list-item-avatar-two-line"}},'v-skeleton-loader',_vm.attrs,false)):_vm._e()],1):_vm._e(),_vm._l((_vm.filteredChildren),function(value,index){return _c('div',{key:index,staticClass:"guardian-class",on:{"click":function($event){return _vm.selectChild(index)}}},[(index < _vm.expand || _vm.result)?_c('div',{staticClass:"class-info"},[_c('img',{attrs:{"src":value.profile_image,"alt":""}}),_c('div',{staticClass:"guardian-name"},[_c('h1',[_vm._v(_vm._s(value.full_name))]),_c('p',[_vm._v(" "+_vm._s(value.academic.enroll_code)+" / "+_vm._s(value.academic.grade)+" / "+_vm._s(value.academic.section)+"   /  "+_vm._s(value.academic.roll)+" ")])])]):_vm._e(),(index < _vm.expand && !_vm.result)?_c('div',{staticClass:"class-icon"},[_vm._m(0,true)]):_vm._e(),(_vm.result)?_c('div',{staticClass:"class-icon"},[_c('v-radio-group',{model:{value:(_vm.array_key),callback:function ($$v) {_vm.array_key=$$v},expression:"array_key"}},[_c('v-radio',{attrs:{"value":index,"color":"success"}})],1)],1):_vm._e()])}),(_vm.children.length !== _vm.expand && _vm.result === false)?_c('div',{staticClass:"guardian-expand",on:{"click":function($event){_vm.expand = _vm.children.length}}},[_c('a',[_c('h3',[_vm._v(_vm._s(_vm.children_data_length)+" More (Click to Expand)")])]),_vm._m(1)]):_vm._e(),(
        _vm.children.length == _vm.expand &&
        _vm.children.length > 1 &&
        _vm.children_data_length > 0
      )?_c('div',{staticClass:"guardian-expand",on:{"click":function($event){_vm.expand = 2}}},[_c('a',[_c('h3',[_vm._v("Hide "+_vm._s(_vm.children_data_length)+" More (Click to Contract)")])]),_vm._m(2)]):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"fas fa-chevron-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"fas fa-ellipsis-h"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"fas fa-ellipsis-h"})])
}]

export { render, staticRenderFns }