/** Lazy Load View **/
import NavFactory from "../../../library/NavFactory";

function loadView(view) {
    return () =>
        import ( /* webpackChunkName: "view-[request]" */ `@/modules/academic/_components/${view}.vue`)
}

let routes = [{
        name: 'batches',
        path: '/batches',
        component: loadView('Batch/index'),
        meta: {
            auth: true,
            permission: 'batch-read'
        }
    },
    {
        name: 'grades',
        path: '/grades',
        component: loadView('Grades/index'),
        meta: {
            auth: true,
            permission: 'grade-read'
        }
    },
    {
        name: 'grades-detail',
        path: '/grades/:gradeId',
        component: loadView('Sections/index'),
        meta: {
            auth: true,
            permission: 'section-read'
        }
    },
    {
        name: 'subjects',
        path: '/subjects',
        component: loadView('Subjects/index'),
        meta: {
            auth: true,
            permission: 'subject-read'
        }
    },
    {
        name: 'routines',
        path: '/routines',
        component: loadView('Routines/index'),
        meta: {
            auth: true,
            permission: 'subject-routine-read'
        }
    }, {
        name: 'migrate-routines',
        path: '/migrate-routines',
        component: loadView('MigrateRoutines/index'),
        meta: {
            auth: true,
            permission: 'subject-routine-read'
        }
    },
    {
        name: 'attendance',
        path: '/attendance',
        component: loadView('Attendance/index'),
        meta: {
            auth: true,
            permission: 'attendance-read'
        }
    },
    {
        name: 'assignments',
        path: '/assignments',
        component: loadView('Assignments/index'),
        meta: {
            auth: true,
            permission: 'assignment-read'
        }
    },
    {
        name: 'assignment-detail',
        path: '/assignment/:assignmentId',
        component: loadView('AssignmentDetail/index'),
        meta: {
            auth: true,
            permission: 'assignment-read&assignment-update'
        }
    },
    {
        name: 'opt-group',
        path: '/opt-group',
        component: loadView('OptGroup/bx.new'),
        meta: {
            auth: true,
            permission: 'staff-create'
        }
    }, {
        name: 'attendance-report',
        path: '/attendance-report',
        component: loadView('AttendanceReport/index'),
        meta: {
            auth: true,
            permission: 'staff-create'
        }
    },
    {
        name: 'assignment-report',
        path: '/assignment-report',
        component: loadView('AssignmentReport/index'),
        meta: {
            auth: true,
            permission: "staff-create"
        }
    }, {
        name: 'syllabus',
        path: '/syllabus/:gradeId?/:subjectId?',
        component: loadView('Syllabus/index'),
        meta: {
            auth: true,
            permission: "subject-read"
        }
    },
    {
        name: 'student group',
        path: '/student-groups',
        component: loadView('StudentGroups/index'),
        meta: {
            auth: true,
            permission: "subject-read"
        }
    },
    {
        name: 'remarks',
        path: '/remarks',
        component: loadView('Remarks/index'),
        meta: {
            auth: true,
            permission: "subject-read"
        }
    },
    {
        name: 'student behaviour report',
        path: '/student-behaviour-report',
        component: loadView('StudentBehaviourReport/index'),
        meta: {
            auth: true,
            permission: true
        }
    },
    {
        name: 'student behaviour summary report',
        path: '/student-behaviour-summary-report',
        component: loadView('StudentBehaviourReport/summaryreport'),
        meta: {
            auth: true,
            permission: true
        }
    },
    {
        name: 'affiliation',
        path: '/affiliation',
        component: loadView('Affiliation/index'),
        meta: {
            auth: true,
            // permission: 'batch-read'
        }
    },
    {
        name: 'faculty',
        path: '/faculty/:affiliationId',
        component: loadView('Faculty/index'),
        meta: {
            auth: true,
        }
    },

    {
        name: 'level',
        path: '/level',
        component: loadView('Level/index'),
        meta: {
            auth: true,
            // permission: 'batch-read'
        }
    },

    {
        name: 'program',
        path: '/program/:levelId',
        component: loadView('Program/index'),
        meta: {
            auth: true,
        }
    },
    {
        name: 'student qr logs',
        path: '/student-qr-logs',
        component: loadView('QrLogs/index'),
        meta: {
            auth: true,
            permission: NavFactory.isAdministrator(),
        }
    },
    {
        name: 'student group details',
        path: '/student-groups/:group_id',
        component: loadView('StudentGroups/detail'),
        meta: {
            auth: true,
            permission: 'section-read'
        }
    },

    // {
    //     name: 'syllabus',
    //     path: '/syllabus/:gradeId/:subjectId',
    //     component: loadView('Syllabus/index'),
    //     meta: {
    //         auth: true,
    //         permission: "subject-read"
    //     }
    // },

    // {
    //     name: 'faculty',
    //     path: '/faculty',
    //     component: loadView('Faculty/index'),
    //     meta: {
    //         auth: true,
    //         permission: 'batch-read'
    //     }
    // },
];

export default routes;