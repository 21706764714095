function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/account/_components/${view}.vue`)
}
let routes = [
    {
        path: 'accounting',
        name: 'accounting',
        component: loadView('index'),
        meta: {
            auth: true
        }
    }
];

export default routes;
