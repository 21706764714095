<template>
  <v-btn
    class="mr-1 mt-1 mb-1"
    :disabled="disabled"
    :small="size === 'small'"
    :x-small="size === 'x-small'"
    outlined
    :color="color || 'success'"
    @click="$emit('action')"
  >
    <v-icon left small>{{ icon || "cloud_download" }}</v-icon>
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  props: {
    icon: { required: false },
    permission: { required: false },
    color: { required: false },
    disabled: { required: false, default: false },
    size: { required: false, default: "small" },
  },
  data: () => ({
    hasPermission: false,
  }),
  mounted() {
    if (this.permission === true) {
      this.hasPermission = true;
      return true;
    }

    if (this.permission) this.hasPermission = this.$auth.can(this.permission);
  },
};
</script>
<style lang="scss">
.eac-add-button {
  .v-chip__content {
    cursor: pointer;
  }
}
</style>
