export default class LocalStorageHelper {

    setToStorage(keyName,data){
        if(window.localStorage.getItem(keyName) == undefined || window.localStorage.getItem(keyName) == null)
            window.localStorage.setItem(keyName, JSON.stringify(data));
    }
    getFromStorage(keyName){
        let data  = window.localStorage.getItem(keyName)
        return JSON.parse(data);
    }
    removeFromStorage(keyName){
        if(window.localStorage.getItem(keyName) != undefined || window.localStorage.getItem(keyName) != null)
            window.localStorage.removeItem(keyName);
    }   
}