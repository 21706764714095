<template>
  <div>
    <div id="chart">
      <apexchart
        width="380"
        type="donut"
        :options="options"
        :series="series"
      ></apexchart>
      <!-- <apexchart
                type="donut"
                :options="chartOptions"
                :series="series"
              ></apexchart> -->
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    options: {},
    series: [44, 55, 41, 17, 15],
    // series: [44, 55, 41, 17, 15],
    // series: [
    //   {
    //     data:[44, 55, 41, 17, 15],
    //     // data: [21, 22, 10, 28, 16, 21, 13, 30],
    //   },
    // ],
    // chartOptions: {
    //   chart: {
    //     width: "100%",
    //     type: "donut",
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },

    //   legend: {
    //     position: "bottom",
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         chart: {
    //           width: 200,
    //         },
    //         abels: {
    //           show: false,
    //         },
    //       },
    //     },
    //   ],
    // },
  }),
};
</script>