<template>
  <div style="margin: 0;padding: 0;">
    <!--<v-btn fab class="mx-2" dark small color="success" v-if="excel">-->
    <!--<v-icon style="color: white; cursor: pointer"-->
    <!--@click="downloadExcel()">cloud_download-->
    <!--</v-icon>-->
    <!--</v-btn>-->
    <!---->
    <download-button class="mr-2" v-if="excel" @action="downloadExcel()">{{
      labelExcel || label
    }}</download-button>

    <download-button v-if="pdf" @action="downloadPdf()">{{
      labelPdf || label
    }}</download-button>

    <!--<v-btn fab class="mx-2" dark small color="primary" v-if="pdf">-->
    <!--<v-icon color="white" style="cursor: pointer;"-->
    <!--@click="downloadPdf()">print-->
    <!--</v-icon>-->
    <!--</v-btn>-->
  </div>
</template>
<script>
import { passDataToExternalDomain } from "../library/helpers";
import au from "../library/Auth"
import ls from "../library/Storage";
export default {
  props: {
    downloadUrl: {
      //   type: String,
      //   required: true,
      //not required when action is sent
    },
    passAuthToReport: {
      required: false,
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: true,
    },
    excel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
    labelPdf: {
      type: String,
    },
    labelExcel: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
    hasPermission: false,
    disableExcel: false,
    disablePdf: false,
  }),
  mounted() {},
  methods: {
    downloadExcel() {
      let url = this.downloadUrl ? this.downloadUrl + "excel" : null;
      // this.shareCommunicationToken();
      if (this.disableExcel === false) {
        this.disableExcel = true;
        let $this = this;
        if (url && typeof url === "string") {
            url += '&token='+au.user().id;
            window.open(url, '_blank');
          }
        $this.$emit("action");
        $this.disableExcel = false;
        
        // setTimeout(function() {
        // }, 500);
      }
    },
    downloadPdf() {
      let url = this.downloadUrl ? this.downloadUrl + "pdf" : null;
      // this.shareCommunicationToken();
      if (this.disablePdf === false) {
        this.disablePdf = true;
        let $this = this;
        setTimeout(function() {
          if (url && typeof url === "string") {
            url += '&token='+ au.user().id;
            window.open(url, '_blank');
          }
          $this.$emit("action");
          $this.disablePdf = false;
        }, 500);
      }
    },
    async shareCommunicationToken() {
      if (this.passAuthToReport === true) {
        return passDataToExternalDomain("reporting");
      }
    },
  },
};
</script>
