<template>
  <v-card-title>
    <v-flex>
      <v-select
        :loading="grade.grade.fetching"
        :items="gradeItems"
        outlined
        dense
        class="pa-0"
        label="Select grade"
        v-model="grade.grade.selected"
      />
    </v-flex>

    <v-flex v-if="!onlyGrade">
      <v-select
        :disabled="!sectionItems.length"
        :loading="grade.section.fetching"
        :items="sectionItems"
        class="pa-0"
        outlined
        dense
        label="Select section"
        v-model="grade.section.selected"
      />
    </v-flex>

    <slot></slot>
  </v-card-title>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    filter: {
      type: Boolean,
      default: false,
    },
    onlyGrade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    "grade.grade.selected": function(value) {
      let $this = this;
      this.grade.grade.items.map((item) => {
        if ($this.grade.grade.selected === item.id) {
          $this.SET_SECTIONS(item.sections);
        }
      });
      this.$emit("onSectionChange");
    },
    "grade.section.selected": function(value) {
      this.$emit("onSectionChange");
    },
  },
  computed: {
    ...mapState(["grade"]),
    gradeItems() {
      return this.grade.grade.items.map((item) => {
        return { value: item.id, text: item.name };
      });
    },
    sectionItems() {
      return this.grade.section.items.map((item) => {
        return { value: item.id, text: item.name };
      });
    },
  },
  mounted() {
    this.getGrades({ filter: this.filter });
  },
  methods: {
    ...mapActions(["getGrades"]),
    ...mapMutations(["SET_SECTIONS"]),
  },
};
</script>
<style lang="scss"></style>
