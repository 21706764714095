<template>
  <v-dialog
    v-if="hasPermission && showButton"
    v-model="dialog"
    persistent
    max-width="350"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :loading="loading || false"
        :disabled="disabled || false"
        :x-small="xSmall || true"
        :large="large || false"
        :block="block || false"
        :outlined="outlined === undefined ? true : outlined"
        :color="color || 'warning'"
        class="ml-1 mb-1 mt-1"
        v-on="on"
      >
        <v-icon x-small>{{ icon || "edit" }}</v-icon>
        <strong class="ml-1">{{ label || "Edit" }}</strong>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="title pa-3 warning white--text">
        <v-icon class="mr-2 white--text">warning</v-icon>
        Please, wait!
      </v-card-title>
      <v-card-text>
        <br />
        <alert-message
          extra-small
          type="warning"
          title="Please wait !!"
          :message="message || 'Are you sure you want to edit this item?'"
        ></alert-message>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          small
          @click="(dialog = false), $emit('disagree')"
          >Cancel</v-btn
        >
        <v-btn
          :loading="loading"
          color="warning"
          text
          small
          @click="(dialog = false), $emit('agree')"
          >{{ editText || "Edit" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isCurrentBatch } from "../library/helpers";

export default {
  // props: [
  //   "icon",
  //   "permission",
  //   "message",
  //   "outlined",
  //   "label",
  //   "color",
  //   "disabled",
  //   "xSmall",
  //   "block",
  //   "large",
  //   "editText",
  //   "loading",
  // ],
  props: {
    icon: { required: false },
    permission: { required: false },
    message: { required: false },
    outlined: { required: false },
    label: { required: false },
    color: { required: false },
    disabled: { required: false, default: false },
    xSmall: { required: false },
    block: { required: false },
    large: { required: false },
    editText: { required: false },
    loading: { required: false },
    onCurrentBatch: { required: false, default: true },
  },
  data: () => ({
    dialog: false,
    hasPermission: false,
  }),
  computed: {
    showButton() {
      if (this.onCurrentBatch) {
        return this.isCurrentBatch();
      }
      if (this.$auth.isAdmin() || this.$auth.isSuper()) {
        return true;
      }
      return true;
    },
  },
  mounted() {
    if (this.permission) this.hasPermission = this.$auth.can(this.permission);
  },
  methods: {
    isCurrentBatch,
  },
};
</script>