export default {
    staff: {
        items: [],
        filter: {
            role: ''
        }
        // fetching: false,
        // selected: ''
    },
    guardian: {
        items: [],
        filter: {},
        unassigned: 0
        // fetching: false,
        // selected: ''
    }
};
