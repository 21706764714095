<template>
  <a>
    <div class="terminal-section" @click="$emit('click')">
      <div>
        <h4>{{ info }}</h4>
        <h1>{{ title }}</h1>
      </div>
      <div class="terminal-icon">
        <i :class="icon"></i>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
    icon : {
      required:false,
      default:'far fa-clock'
    }
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>

