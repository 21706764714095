let base = window.location.origin;
let uri = 'https://transit.eacademynepal.com';
if (base === 'http://localhost:8080' || base === 'http://localhost:8081')
	uri = 'http://transit.test';
else if (base === 'https://lms.iot-nepal.com' || base === 'https://eacademy.sahakaryatech.com')
	uri = 'https://transit.sahakaryatech.com';


const config = {
	APP_KEY: '8c2f3fb78fc7e96854a21d0cc71d085b183d4886ff24ff82696c14049f317701',
	SYSTEM_HOST: base,
	BASE_URL: process.env.VUE_APP_CONNECTION === 'QA' ? (process.env.SERVER_URI || 'https://transit.sahakaryatech.com') : uri,
	APP_ENV: process.env.VUE_APP_CONNECTION//replace later with .env
};

if (process.env.VUE_APP_CONNECTION !== 'QA' && process.env.VUE_APP_CONNECTION !== 'LIVE' && process.env.VUE_APP_CONNECTION !== 'DEV') {
	config.BASE_URL = 'https://transit.eacademynepal.com';
}


config.rest = {
	OK: 200,
	CREATED: 201,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	UNACCEPTABLE: 406
};

export default config


// 19, 137, 253 primary
// 3, 169, 244 secondary
