var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:!_vm.birthdayUse ? 'primary--border elevation-0' :'',attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pb-1"},[_c('alert-message',{attrs:{"type":"warning","title":"Service Charge Alert","message":"SMS Rate is inclusive of 10% T.C. service charge and 13% Government Tax. Click Details to view the calculation info.","extraSmall":""}}),_c('br'),(!_vm.birthdayUse)?_c('v-data-table',{attrs:{"loading":_vm.loading,"hide-default-footer":"","items":[
        { text: 'Balance' },
        { text: 'Rate Per SMS' },
        { text: 'In Queue' },
      ],"headers":[
        ...[
          { text: 'Head', align: 'left', sortable: false },
          { text: '#', align: 'right', sortable: false },
        ],
      ]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('th',[_vm._v(_vm._s(item.text))]),_c('td',{staticClass:"text-right"},[(item.text === 'Balance')?_c('span',[_vm._v(_vm._s(_vm.balance.currency())+" "),_c('small',[_c('a',{attrs:{"href":_vm.sms_url,"target":"_blank"}},[_c('u',[_vm._v("(Recharge)")])])])]):(item.text === 'Rate Per SMS')?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.ratePerSMS.currency()))]),_vm._v("  "),_c('small',[_c('a',{attrs:{"href":""}},[_c('u',[_vm._v("(Details)")])])])]):(item.text === 'In Queue')?_c('span',[_vm._v(_vm._s(_vm.smsQueue))]):_vm._e()])])]}}],null,false,1968952228)}):_vm._e(),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }