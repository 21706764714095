<template>
  <v-card-actions class="filter-footer-action">
    <v-layout row wrap style="margin-bottom: 20px">
      <v-flex xs3>
        <v-btn
          style="background-color: #fff"
          elevation="0"
          color="default"
          large
          block
        >
          {{title}}
        </v-btn>
      </v-flex>
      <v-flex xs9 pl-2>
        <v-btn elevation="0" color="primary" block large> {{title2}} </v-btn>
      </v-flex>
    </v-layout>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    title2: {
      required: true,
      type: String,
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
