/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/templeteManger/_components/${view}.vue`)
}

let routes = [
    {
        name: 'newcertifiatetemplate',
        path: '/newcertificate-template',
        component: loadView('certificateTemplate/index'),
        meta: {
            auth: true,
            permission: 'template-create'
        },
        
    },{
        name: 'entrancecardtemplate',
        path: '/entrancecard-template',
        component: loadView('certificateTemplate/EntranceCardTemplate'),
        meta: {
            auth: true,
            permission: 'template-create'
        }
    },{
        name: 'idcardtemplate',
        path: '/idcard-template',
        component: loadView('certificateTemplate/IdCardTemplate'),
        meta: {
            auth: true,
            permission: 'template-create'
        }
    },
];

export default routes;